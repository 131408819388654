import React from 'react';
import { Button } from 'reactstrap';

const ClearFilterBtn = ({resetFilters}) => {
  return (
    <div className='clear-button me-5'>
      <Button
        color='secondary'
        className='quidzi-btn-light'
        onClick={resetFilters}
      >
        Clear Filters
      </Button>
    </div>
  );
};

export default ClearFilterBtn;
