import axios from 'axios';

import config from '../../config/index';

//get roles with server side actions
export async function getAllLibraryComponentsReq(
  skip,
  limit,
  sortVal,
  realNameFilter,
  realOwnerFilter,
  realTypeFilter,
  activateFilterStatus
) {
  try {
    const response = await axios.get(
      config.axiosConnectionUrl +
      'lc/' +
      'table?skip=' +
      skip +
      '&limit=' +
      limit +
      '&sortid=' +
      sortVal?.id +
      '&sortdesc=' +
      (sortVal?.desc ? 'DESC' : 'ASC') +
      '&namefilter=' +
      realNameFilter +
      '&ownerfilter=' +
      realOwnerFilter +
      '&typefilter=' +
      realTypeFilter +
      '&activated=' +
      activateFilterStatus
    );

    return response;
  } catch (error) {
    console.log(error);
    // return error.response.data.message
  }
}

export async function deleteLibraryComponentReq(id) {
  try {
    const response = await axios.delete(
      config.axiosConnectionUrl + 'lc/' + id
    );
    return response;
  } catch (e) {
    return e;
  }
}

export async function getLibraryComponentReq(id) {
  const response = await axios.get(
    config.axiosConnectionUrl + 'lc/' + `${id}`
  );
  return response;
}

export async function updateLibraryComponentReq(id, data) {
  const response = await axios.put(
    config.axiosConnectionUrl + 'lc/' + `${id}`,
    data
  );
  return response;
}

export async function statsForLibPageReq(type = '') {
  try {
    const response = await axios.get(config.axiosConnectionUrl + 'stats/lib/' + type);
    return response;
  } catch (error) {
    console.log(error);
    // return error.response.data.message;
  }
}
