import  './index.scss';
import bGFullscreenSvg from '../../assets/images/layouts/entry_background.svg';

export const LoginBackGround = () => {
  return (
    <div className={'login'}>
      <img className={'login_bg'} src={bGFullscreenSvg} alt="" />
    </div>
  );
};
