import React from 'react';
import { Redirect } from 'react-router-dom';

// Profile
import UserProfile from '../pages/Authentication/user-profile';

// Authentication related pages
import Login from '../pages/Authentication/Login';

//  // Inner Authentication
//

import ActivitiesPage from "../pages/default/activities/activities.page"

import UsersCreatePage from "../pages/default/user/user-create/user-create.page.jsx"
import UserEditPage from '../pages/default/user-edit/user-edit.page.jsx';
import RolePage from '../pages/default/role/role.page.jsx';
import UsersPage from '../pages/default/user/user.page.jsx';
import PlaylistsPage from "../pages/default/playlists/playlist.page"
import EditPlaylistsPage from "../pages/default/playlists/playlist-edit/playlist-edit.page"
import LibraryComponentsPage from "../pages/default/library-components/libraryComponents.page"
import EditLibraryComponentsPage from "../pages/default/library-components/library-components-edit/library-components-edit.page"
import ChannelsPage from "../pages/default/channels/channel.page"
import ChannelsEditPage from "../pages/default/channels/channel-edit/channel-edit.page"

import loginPage from '../pages/Authentication/Login';

const userRoutes = [
  // activities
  { path: "/activities", component: ActivitiesPage },
  // //profile
  { path: "/profile", component: UserProfile },

  //Hygrid
  { path: "/users", component: UsersPage },
  { path: "/users/create", component: UsersCreatePage },
  { path: "/users/edit/:id", component: UserEditPage },
  { path: "/roles", component: RolePage },
  { path: "/playlists", component: PlaylistsPage },
  { path: "/playlists/:id", component: EditPlaylistsPage },
  { path: "/library-components", component: LibraryComponentsPage },
  { path: "/pages", component: LibraryComponentsPage },
  { path: "/components", component: LibraryComponentsPage },
  { path: "/pdf", component: LibraryComponentsPage },
  { path: "/media", component: LibraryComponentsPage },
  { path: "/links", component: LibraryComponentsPage },
  { path: "/files", component: LibraryComponentsPage },
  { path: "/library-components/:id", component: EditLibraryComponentsPage },
  { path: "/channels", component: ChannelsPage },
  { path: "/channels/:id", component: ChannelsEditPage },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/users" /> },
]

const authRoutes = [

    { path: '/login', component: Login },




];

export { userRoutes, authRoutes };
