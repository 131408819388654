import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, CardBody, Col, Container, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { createUser, deactivateUserReq, deleteUserReq, inviteUserByEmail } from '../../../services/axios/axios.user';

import { setSortStateAc } from '../../../store/table/sort/actions';
import { getRolesReq } from '../../../services/axios/axios.roles';
import { setNavValues } from '../../../store/nav_breadcrumbs/actions';
import useThrottle from '../../../services/shared_handlers/debouncer';
import { Offsymbol, OnSymbol } from '../../../components/shared/switchSymbolsDefault';
import { columnsContent, getAllUsersForTable, Styles } from './user_table';
import CreateUserModal from './modalCreateUser';
import CreateUserInviteModal from './modalInviteUser';
import './user.page.scss';
import '../../../assets/styles_enemy_unknown/hell_styles.scss';
import { Table } from '../../../components/shared/reactTable';
import config from '../../../config';
import ClearFilterBtn from '../../../components/shared/ClearFilterBtn';
import Stats from '../../../components/Stats';
import { typesForStatsComponent } from '../../../components/Stats/helper';

function UsersPage() {

  // states
  const [rolesArr, setRoles] = useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [nameFilterP, setNameFilterP] = useState('');
  const [realNameFilter, setRealNameFilter] = useState('');
  const [pageCount, setPageCount] = React.useState(0);
  const [phoneFilterP, setPhoneFilterP] = useState('');
  const [emailFilterP, setEmailFilterP] = useState('');
  const [realPhoneFilter, setRealPhoneFilter] = useState('');
  const [realEmailFilter, setRealEmailFilter] = useState('');
  const [roleFilterP, setRoleFilterP] = useState('');
  const [activateFilterStatus, setActivateFilterStatus] = useState(false);
  const [magicRender, setMagicRender] = useState(false);
  const [userIdForDelete, setUserIdForDelete] = useState('');
  const [modalDeleteStatus, setModalDeleteStatus] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertStatusWarn, setAlertStatusWarn] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [blockedUserAmount, setBlockedUserAmount] = useState(0);
  const [totalUserAmount, setTotalUserAmount] = useState(0);
  const [lastMonthCreated, setLastMonthCreated] = useState(0);
  const [lastWeekCreated, setLastWeekCreated] = useState(0);
  const [createModalStatus, setCreateModalStatus] = useState(false);
  const [inviteModalStatus, setInviteModalStatus] = useState(false);
  const [userName, setUserName] = useState('');
  const [userNameValid, setUserNameValid] = useState(false);
  const [userFullName, setUserFullName] = useState('');
  const [userFullNameValid, setUserFullNameValid] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userEmailValid, setUserEmailValid] = useState(false);
  const [userPhone, setUserPhone] = useState('');
  const [userPhoneValid, setUserPhoneValid] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userRoleValid, setUserRoleValid] = useState('');
  const [userNotes, setUserNotes] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isUpperLetterValid, setIsUpperLetterValid] = useState(false);
  const [isLowerLetterValid, setIsLowerLetterValid] = useState(false);
  const [isAnyNumberValid, setIsAnyNumberValid] = useState(false);
  const [isStringAlphaNumValid, setisStringAlphaNumValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [matchStatus, setMatchStatus] = useState(false);
  const [confirmPasswords, setConfirmPasswords] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [inviteFormValid, setInviteFormValid] = useState(false);
  const [emailErrorStatus, setEmailErrorStatus] = useState(false);
  const [phoneErrorStatus, setPhoneErrorStatus] = useState(false);

  const [forcedUpdate, setForcedUpdate] = useState(false);
  //consts
  const history = useHistory();
  const columns = React.useMemo(
    () => columnsContent,
    []
  );
  const sortVal = useSelector(state => state.sort.sortBy);
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]));
  }, []);
  const [debounce] = useThrottle(1000);


  //async section
  //data for table

  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const startRow = pageSize * pageIndex;
    const data = await getAllUsersForTable(startRow, pageSize,
      sortVal, realNameFilter,
      realPhoneFilter, realEmailFilter, roleFilterP,
      activateFilterStatus, goUserEditPage,
      deactivateUser, setUserIdForDelete,
      setModalDeleteStatus, rolesArr, magicRender, setMagicRender);
    if (!data) {
      return;
    }
    const {
      usersForPush,
      amount
    } = data;
    const serverData = usersForPush;
    setData(serverData);
    setPageCount(Math.ceil(amount / pageSize));
    setLoading(false);
    setForcedUpdate(false);
  }, [sortVal, realNameFilter, realPhoneFilter, realEmailFilter, roleFilterP, activateFilterStatus, magicRender, forcedUpdate, rolesArr]);

  //roles for dropdown
  const getRoles = async () => {
    const responseRoles = await getRolesReq();
    setRoles(responseRoles?.data.roles);
  };

  ///deactivate/activate
  const deactivateUser = async id => {
    const response = await deactivateUserReq(id);
    if (response?.statusText === 'OK') {
      setMagicRender(!magicRender);
      if (!!response.data.result) {
        setAlertStatus(true);
        setAlertText('User activated');
        setTimeout(() => {
          setAlertStatus(false);
        }, 2000);
      } else {
        setAlertStatusWarn(true);
        setAlertText('User deactivated');
        setTimeout(() => {
          setAlertStatusWarn(false);
        }, 2000);
      }
      setForcedUpdate(true);
      return;
    } else {
      setAlertStatusWarn(true);
      setAlertText('Something went wrong. Please contact administrator');
      setTimeout(() => {
        setAlertStatusWarn(false);
      }, 5000);
    }
  };

//delete
  const deleteUser = async () => {
    const response = await deleteUserReq(userIdForDelete);

    if (response.status === 204) {
      setMagicRender(!magicRender);
      setModalDeleteStatus(false);
      setAlertStatusWarn(true);
      setAlertText('User deleted');
      setTimeout(() => {
        setAlertStatusWarn(false);
      }, 2000);
      setForcedUpdate(true);
    }
  };

  //set data for cards

  //user actions
  //open create modal
  const tog_create_modal = () => {
    setCreateModalStatus(!createModalStatus);
    document.body.classList.add('no_padding');
  };

  //open invite modal
  const tog_invite_modal = () => {
    setInviteModalStatus(!inviteModalStatus);
    document.body.classList.add('no_padding');
  };
  const goUserEditPage = e => {
    const id = e.target.dataset.id;
    history.push({ pathname: `../users/edit/${id}`, state: id });
  };


  //filter handling
  const resetFilters = () => {
    setNameFilterP('');
    setRealNameFilter('');
    setPhoneFilterP('');
    setRealPhoneFilter('');
    setEmailFilterP('');
    setRealEmailFilter('');
    setActivateFilterStatus(false);
  };
  ///filter by phone
  const phoneFilter = e => {
    const value = e.target.value;
    setPhoneFilterP(value);
  };

  /////////create user modal catch input values
  ///creation modal
  const editFormInputs = e => {
    const target = e.target;
    const value = target.value;
    switch (target.name) {
      case 'login':
        setUserName(value);
        setUserNameValid(value.trim().length > 1);
        break;
      case 'fullname':
        setUserFullName(value);
        setUserFullNameValid(value.trim().length > 1);
        break;
      case 'email':
        setUserEmail(value);
        setUserEmailValid(/^(([^()[\]\\.,;:\s@"]+(\.[^()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          .test(String(e.target.value).toLowerCase()));
        break;
      case 'role':
        setUserRole(value);
        setUserRoleValid(value.trim().length > 1);
        break;
      case 'notes':
        setUserNotes(value);
        break;
      case 'password':
        setUserPassword(value);
        if (value.trim().length >= 8) {
          setIsLengthValid(true);
        } else {
          setIsLengthValid(false);
        }
        //upperblock
        setIsUpperLetterValid(value.toLowerCase() != value);
        //lower block
        setIsLowerLetterValid(value.toUpperCase() != value);
        //number block
        setIsAnyNumberValid(/\d/i.test(value));
        // setisStringAlphaNumValid(isAlphaNumeric(value))
        setisStringAlphaNumValid(/(?=.*?[#?!@$%^&*-])/.test(value));
        if (!!confirmPasswords) {
          setMatchStatus(value === confirmPasswords);
        }
        break;
      case 'password1':
        setConfirmPasswords(value);
        setMatchStatus(value === userPassword);
        break;
      case 'phone':
        setUserPhone(value);
        setUserPhoneValid(value.trim().length > 8 && /^([+\d].*)?\d$/.test(value));
        break;
    }
  };

  ///save changes
  const saveChanges = async () => {
    try {
      const userData = {
        username: userName,
        fullname: userFullName,
        email: userEmail,
        role: userRole,
        notes: userNotes,
        password: userPassword,
        phoneNumber: userPhone
      };

      const response = await createUser(userData);
      if (response.data.message === 'user created') {
        setMagicRender(!magicRender);
        tog_create_modal();
        setAlertText('User created');
        setAlertStatus(true);
        setTimeout(() => {
          setAlertStatus(false);
        }, 2000);
        setCreateModalStatus(false);
      } else if (response.data.message.includes('email')) {
        setEmailErrorStatus(true);
        setTimeout(() => {
          setEmailErrorStatus(false);
        }, 5000);
      } else if (response.data.message.includes('phone')) {
        setPhoneErrorStatus(true);
        setTimeout(() => {
          setPhoneErrorStatus(false);
        }, 5000);
      } else {
        setAlertStatusWarn(true);
        setAlertText('Something went wrong. Please contact administrator');
        setTimeout(() => {
          setAlertStatusWarn(false);
        }, 5000);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  ///save changes
  const inviteUser = async () => {
    try {
      const userData = {
        email: userEmail,
        role: userRole,
        phoneNumber: userPhone
      };
      const response = await inviteUserByEmail(userData);
      if (response.data.message === 'user created') {
        setMagicRender(!magicRender);
        tog_invite_modal();
        setAlertText('User created');
        setAlertStatus(true);
        setTimeout(() => {
          setAlertStatus(false);
        }, 2000);
        setCreateModalStatus(false);
      } else if (response.data.message.includes('email')) {
        setEmailErrorStatus(true);
        setTimeout(() => {
          setEmailErrorStatus(false);
        }, 5000);
      } else if (response.data.message.includes('phone')) {
        setPhoneErrorStatus(true);
        setTimeout(() => {
          setPhoneErrorStatus(false);
        }, 5000);
      } else {
        setAlertStatusWarn(true);
        setAlertText('Something went wrong. Please contact administrator');
        setTimeout(() => {
          setAlertStatusWarn(false);
        }, 5000);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    setIsPasswordValid(
      isLengthValid &&
      isUpperLetterValid &&
      isLowerLetterValid &&
      isAnyNumberValid &&
      isStringAlphaNumValid &&
      matchStatus
    );
  }, [
    isLengthValid,
    isUpperLetterValid,
    isLowerLetterValid,
    isAnyNumberValid,
    isStringAlphaNumValid,
    matchStatus
  ]);

  useEffect(() => {
    setFormValid(isPasswordValid
      && userFullNameValid && userEmailValid
      && userRoleValid && userPhoneValid);
  }, [
    isPasswordValid, userFullNameValid, userEmailValid
    , userRoleValid, userPhoneValid
  ]);

  useEffect(() => {
    setInviteFormValid(userEmailValid
      && userRoleValid && userPhoneValid);
  }, [
    userEmailValid, userRoleValid, userPhoneValid
  ]);

  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title2: 'Users' }));
    getRoles();
  }, []);

  return (
    <Container fluid className='providers-main-wrapper'>
      <Styles className='provider-white-card'>
        <CreateUserModal
          createModalStatus={createModalStatus}
          setCreateModalStatus={setCreateModalStatus}
          tog_create_modal={tog_create_modal}
          editFormInputs={editFormInputs}
          rolesArr={rolesArr}
          saveChanges={saveChanges}
          emailErrorStatus={emailErrorStatus}
          phoneErrorStatus={phoneErrorStatus}
          validation={{
            isLengthValid, isUpperLetterValid,
            isLowerLetterValid, isAnyNumberValid,
            isStringAlphaNumValid, matchStatus,
            formValid
          }}
        />
        <CreateUserInviteModal
          inviteModalStatus={inviteModalStatus}
          setInviteModalStatus={setInviteModalStatus}
          tog_invite_modal={tog_invite_modal}
          editFormInputs={editFormInputs}
          rolesArr={rolesArr}
          inviteUser={inviteUser}
          emailErrorStatus={emailErrorStatus}
          phoneErrorStatus={phoneErrorStatus}
          validation={{
            isLengthValid, isUpperLetterValid,
            isLowerLetterValid, isAnyNumberValid,
            isStringAlphaNumValid, matchStatus,
            inviteFormValid
          }}
        />
        {modalDeleteStatus ? (
          <SweetAlert
            title='Attention'
            success={false}
            showCancel
            confirmBtnText='No'
            cancelBtnText='Delete'
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='warning'
            onConfirm={() => {
              setModalDeleteStatus(false);
            }}
            onCancel={() => {
              deleteUser();
            }}
          >
            Are you sure want to delete this user?
          </SweetAlert>
        ) : null}
        <Row className='pt-0 mt-4 row-all-content'>
          <Stats type={typesForStatsComponent.users} magicRender={magicRender} />
          <Col className='col-table-actions' style={{ marginLeft: 12 }}>
            <CardBody className='p-0'>
              <Row>
                <Col className={'d-flex justify-content-start gap-4'} xl={6} md={12}>
                  <Row className={'w-100'}>
                    <Col xl={3} md={6} className={'mb-3'}>
                      <div className='col-md-12'>
                        <input
                          value={nameFilterP}
                          className='quidzi-input-borderered'
                          placeholder='Search by name'
                          type='text'
                          onChange={(e) => {
                            setNameFilterP(e.target.value);
                            debounce(() => () => setRealNameFilter(e.target.value));
                          }}

                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6} className={'mb-3'}>
                      <div className='col-md-12'>
                        <input
                          placeholder='Search by phone'
                          value={phoneFilterP}
                          className='quidzi-input-borderered'
                          type='text'
                          onChange={(e) => {
                            setPhoneFilterP(e.target.value);
                            debounce(() => () => setRealPhoneFilter(e.target.value));
                          }}

                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6} className={'mb-3'}>
                      <div className='col-md-12 '>
                        <input
                          placeholder='Search by email'
                          value={emailFilterP}
                          className='quidzi-input-borderered'
                          type='text'
                          onChange={(e) => {
                            setEmailFilterP(e.target.value);
                            debounce(() => () => setRealEmailFilter(e.target.value));
                          }}

                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6}> <ClearFilterBtn resetFilters={resetFilters} /></Col>
                  </Row>
                </Col>
                <Col className={'d-flex justify-content-end flex-row mb-3 pe-4'} xl={6} md={12}>
                  <Col className={' d-flex  flex-row gap-3 justify-content-end'}>
                    <div className='switch-component d-flex gap-2'>
                      <div className='mt-1'>Show Deactivated</div>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor='#FFC72C'
                        onChange={e => {
                          setActivateFilterStatus(!activateFilterStatus);
                        }}
                        checked={activateFilterStatus}
                      />
                    </div>
                    <button
                      className='quidzi-btn-light'
                      onClick={() => {
                        history.push('../users/create');
                      }}
                    >
                      Create
                    </button>


                  </Col>
                </Col>

              </Row>
            </CardBody>
          </Col>
        </Row>
        <Row className='hide-scrollbar row-table-content'>
          <Col>
            <Table
              columns={columns}
              data={data}
              fetchData={fetchData}
              loading={loading}
              pageCount={pageCount}
              onSort={onSortedChange}
              filterFields={{ realNameFilter, realPhoneFilter, realEmailFilter }}
            />
          </Col>
        </Row>

        {alertStatus && (
          <div className='alert-wrapper'>
            <Alert color='success' className='bg-success text-white success-custom alert-card'>
              <strong>Well done!</strong> {alertText}
            </Alert>
          </div>
        )}
        {alertStatusWarn && (
          <div className='alert-wrapper'>
            <Alert color='warning' className='bg-warning text-white success-custom alert-card'>
              <strong>Oops!</strong> {alertText}
            </Alert>
          </div>
        )}
      </Styles>
    </Container>
  );
}

export default UsersPage;
