import React, { useState } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as PageIcon } from '../../assets/images/small/left-main-menu/page_icon_16_16.svg';
import { ReactComponent as LibraryIcon } from '../../assets/images/small/left-main-menu/library_icon_16_16.svg';
// import { ReactComponent as SlideIcon } from '../../assets/images/small/left-main-menu/slide_icon_16_16.svg';
import { ReactComponent as ComponentIcon } from '../../assets/images/small/left-main-menu/component_icon_16_16.svg';
import { ReactComponent as MediaIcon } from '../../assets/images/small/left-main-menu/media_icon_16_16.svg';
import { ReactComponent as PdfIcon } from '../../assets/images/small/left-main-menu/pdf_v2.svg';
import { ReactComponent as LinksIcon } from '../../assets/images/small/left-main-menu/link_icon_16_16.svg';
import { ReactComponent as FilesIcon } from '../../assets/images/small/left-main-menu/file_icon_16_16.svg';
import { activeExpandPaths } from '../../pages/default/library-components/helpers';



const LibraryCollapseExpand = () => {
  const location = useLocation();
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(activeExpandPaths[location.pathname] || false);

  const toggleExpanded = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
    history.push('/library-components');
  };

  return (
    <div>
      <CardBody>
        <div className='accordion' id='accordion'>
          <div className='accordion-item'>
              <li  id='headingOne'>
                <Link to='/library-components' onClick={toggleExpanded} className='waves-effect'>
                  <LibraryIcon/>
                  <span>Library Items</span>
                </Link>
              </li>




            <Collapse isOpen={isExpanded} className='accordion-collapse'>
              <div className='accordion-body pt-0'>
                <ul className={`metismenu list-unstyled ${isExpanded ? 'i-can-see-block' : ''}`}
                    id='side-menu-collapsed'>

                  <li>
                    <Link to='/pages' className='waves-effect'>
                   <PageIcon/>
                      <span>Pages</span>
                    </Link>
                  </li>

                  <li>
                    <Link to='/components' className='waves-effect'>
                      <ComponentIcon/>
                      <span>Components</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/pdf' className='waves-effect'>
                      <PdfIcon/>
                      <span>PDF</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/media' className='waves-effect'>
                     <MediaIcon/>
                      <span>Media</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/links' className='waves-effect'>
                    <LinksIcon/>
                      <span>Links</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/files' className='waves-effect'>
                      <FilesIcon/>
                      <span>Files</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>

        </div>
      </CardBody>
    </div>
  );
};

export default LibraryCollapseExpand;
