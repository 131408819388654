import {Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"

import chevron from "../../../assets/default/images/ico/chevronForSelect.png"

import "./user.page.scss"

export default function CreateUserInviteModal({
                                          inviteModalStatus, setInviteModalStatus,
                                          tog_invite_modal, editFormInputs,
                                          rolesArr, inviteUser,
                                          validation,emailErrorStatus,
                                          phoneErrorStatus
                                        }) {

  const {
    inviteFormValid
  } = validation

  return (
    <Modal
      isOpen={inviteModalStatus}
      toggle={() => {
        tog_invite_modal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          User Constructor
        </h5>
        <button
          type="button"
          onClick={() => {
            setInviteModalStatus(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <AvForm>
        <div className="modal-body">
          <div className="mb-3">
            <AvField
              name="email"
              label="E-Mail"
              onChange={editFormInputs}
              placeholder="Enter Valid Email"
              type="email"
              errorMessage="Invalid Email"
              validate={{
                required: { value: true },
                email: { value: true }
              }}
            />
            {emailErrorStatus &&
            <small style={{ color: "red" }}>User with this email exists
            </small>}
          </div>
          <div className="mb-3">
            <AvField
              name="phone"
              onChange={editFormInputs}
              label="Phone number  "
              placeholder="Type phone number"
              errorMessage="Incorrect phone number"
              validate={{
                required: { value: true },
                pattern: {
                  value: "^([+\\d].*)?\\d$",
                  errorMessage: "Only Digits and +"
                }
              }}
            />
            {phoneErrorStatus &&
            <small style={{ color: "red" }}>User with this phone number exists
            </small>}
          </div>
          <div className="mb-3 chevron-container">
            <img src={chevron} className="chevron-img-user-page in-modal" alt="" />
            <AvField
              name="role"
              onChange={editFormInputs}
              label="Pick a role"
              placeholder="Choose role"
              type="select"
              errorMessage="Choose Role"
              validate={{
                required: { value: true }
              }}
              className="warning"
            >
              <option></option>
              {" "}
              {rolesArr?.map((role, index) => {
                if (role.is_active) {
                  const string = role.name

                  return (
                    <option value={role._id} key={"role4" + index + role._id}>
                      {string.charAt(0).toUpperCase() +
                      string.slice(1)}
                    </option>
                  )
                }
              })}
            </AvField>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_invite_modal()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            disabled={!inviteFormValid}
            type="submit"
            className="btn btn-primary waves-effect waves-light"
            onClick={inviteUser}
          >
            Invite User
          </button>
        </div>
      </AvForm>
    </Modal>
  )

}
