import defaultLogo from '../assets/images/logo.svg';
import defaultSmallLogo from '../assets/images/sm-logo-quidzi.svg';
import noTitleLogo from '../assets/images/icon_stage.svg';
import store from '../store';

const config = {
  // axiosConnectionUrl: process.env.REACT_APP_BACKEND_API_CONNECTION_URL,
  axiosConnectionUrl: process.env.REACT_APP_BACKEND_API_CONNECTION_URL,
  // axiosConnectionUrl: process.env.REACT_APP_BACKEND_API_CONNECTION_URL,
  breadCrumbsFirstTitle: 'Quidzi',
  pageTitleAdditional: ' Quidzi Admin Dashboard',
  footerMessage: 'Quidzi',
  loginPageProps: {
    title: 'Quidzi Admin Dashboard',
    messageH: 'Welcome to Quidzi Admin Dashboard',
    messageF: 'some addition action message',
    logo: noTitleLogo
  },
  reduxStore: store,
  leftSideBarLogo: defaultLogo,
  leftSideBarSmallLogo: defaultSmallLogo
};


export default config;
