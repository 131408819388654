import React from "react"
import moment from "moment"

import { getAllPlaylistsReq } from "../../../services/axios/axios.playlists"
import { stopNoSortCellSorting } from '../../../services/table-helpers';
import ActionsCell from '../../../components/shared/ActionsCell';

//table columns
export const columnsContent = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Owner email",
    accessor: "email",
  },
  {
    Header: "Crop Url",
    accessor: "cropUrl",
    cannotBeSorted: true,
  },
  {
    Header: "Duration",
    accessor: "duration",
    cannotBeSorted: true,
  },  {
    Header: "Shared",
    accessor: "shared",
    cannotBeSorted: true,
  },
  {
    Header: "Published",
    accessor: "publishManagerConnection",
    cannotBeSorted: true,
  },
  {
    Header: "Link Pages",
    accessor: "linkpagesamount",
  },
  {
    Header: "Date Created",
    accessor: "date",
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
    cannotBeSorted: true,
  },
  {
    Header: "Action",
    accessor: "action",
    cannotBeSorted: true,
  },
]

///request for data
export const getAllPlaylists = async (
  skip,
  limit,
  sortVal,
  realNameFilter,
  realOwnerFilter,
  activateFilterStatus,
  setPlaylistIdForDelete,
  setModalDeleteStatus,
  history
) => {
  if (stopNoSortCellSorting(columnsContent, sortVal?.id)) {
    return;
  }
  let results = await getAllPlaylistsReq(
    skip,
    limit,
    sortVal,
    realNameFilter,realOwnerFilter,
    activateFilterStatus
  )
  const playlistsFromBack = results?.data.playlistArr
    ? results.data.playlistArr
    : []
  const amount = results?.data.amountG
    ? results.data.amountG ?? results.data.length
    : 0
  const playlistsToPush = []

  if (playlistsFromBack && playlistsFromBack?.length) {
    playlistsFromBack.map(playlist => {
      playlistsToPush.push({
        title: (
          <div key={"title-" + playlist.id}>
            {playlist?.title}
          </div>
        ),
        email: (
          <div key={"email-" + playlist.id}>
            {playlist?.owner}
          </div>
        ),
        cropUrl: (
          <div key={"desc-" + playlist.id}>
            {playlist?.cropUrl
              ? playlist?.cropUrl.slice(0, 15) +
                "..." +
                playlist?.cropUrl.slice(-15, playlist?.cropUrl.length)
              : ""}
          </div>
        ),
        duration: (
          <div key={"status-" + playlist.id}>
            {playlist?.duration}
          </div>
        ),
        shared: (
          <div key={"shared-" + playlist.id}>
            {playlist?.shared}
          </div>
        ),
        publishManagerConnection: (
          <div key={"perms-" + playlist.id}>
            {playlist?.published}
          </div>
        ),
        linkpagesamount: (
          <div key={"status-" + playlist.id}>
            {playlist?.linkPages}
          </div>
        ),

        date: (
          <div key={"date-" + playlist.id}>
            {moment(playlist?.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
        updatedAt: (
          <div key={"date2-" + playlist.id}>
            {playlist?.updatedAt
              ? moment(playlist?.updatedAt).format("MM/DD/YYYY")
              : "-"}
          </div>
        ),
        action:
          <ActionsCell item={playlist}
                       editCallback={() => history.push("../playlists/" + playlist.id)}
                       deleteCallback={ setPlaylistIdForDelete}
                       deleteModalCallback={setModalDeleteStatus}
          />
      })
    })
  }
  return { playlistsToPush, amount }
}

