import React from 'react';
import moment from 'moment';

import { getAllChannelsReq } from '../../../services/axios/axios.channels';
import { stopNoSortCellSorting } from '../../../services/table-helpers';
import ActionsCell from '../../../components/shared/ActionsCell';

//table columns
export const columnsContent = [
  {
    Header: 'Title',
    accessor: 'name'
  },
  {
    Header: 'Description',
    accessor: 'description',
    cannotBeSorted: true
  },
  {
    Header: 'Owner Email',
    accessor: 'email'
  },
  {
    Header: 'Members QTY',
    accessor: 'usersInChannel',
    cannotBeSorted: true
  },
  {
    Header: 'Playlist QTY',
    accessor: 'playlistAmount',
    cannotBeSorted: true
  },
  {
    Header: 'Date Created',
    accessor: 'date'
  },
  {
    Header: 'Action',
    accessor: 'action',
    cannotBeSorted: true
  }
];

///request for data
export const getChannels = async (
  skip,
  limit,
  sortVal,
  realNameFilter,
  realOwnerFilter,
  activateFilterStatus,
  setchannelIdForDelete,
  setModalDeleteStatus,
  toggleRenameModal,
  setDataForUpdate,
  history
) => {
  if (stopNoSortCellSorting(columnsContent, sortVal?.id)) {
    return;
  }
  let results = await getAllChannelsReq(
    skip,
    limit,
    sortVal,
    realNameFilter,
    realOwnerFilter,
    activateFilterStatus
  );

  const channelsFromBack = results?.data.channels ? results.data.channels : [];
  const amount = results?.data.amountG
    ? results.data.amountG ?? results.data.lenght
    : 0;
  const channelsToPush = [];

  if (channelsFromBack && channelsFromBack?.length) {
    channelsFromBack.map(channel => {
      channelsToPush.push({
        name: (
          <div key={'name-' + channel.id}>
            {channel?.name}
          </div>
        ),
        description: (
          <div key={'desc-' + channel.id}>
            {channel?.description ? channel?.description?.slice(0, 20) + '...' : ''}
          </div>
        ),
        email: (
          <div key={'status-' + channel.id}>
            {channel?.ownerEmail}
          </div>
        ),
        ownerId: (
          <div key={'perms-' + channel.id}>
            {channel?.ownerId}
          </div>
        ),
        playlistAmount: (
          <div key={'date-' + channel.id}>
            {channel?.playlistAmount}
          </div>
        ),
        usersInChannel: (
          <div key={'date-' + channel.id}>
            {channel?.usersInChannel}
          </div>
        ),
        date: (
          <div key={'date-' + channel.id}>
            {moment(channel?.created).format('MM/DD/YYYY')}
          </div>
        ),
        updatedAt: (
          <div key={'date2-' + channel.id}>
            {channel?.updatedAt ? moment(channel?.updatedAt).format('MM/DD/YYYY') : '-'}
          </div>
        ),
        action:
          <ActionsCell item={channel}
                       // editCallback={() => history.push("../channels/" + channel.id)}
                       editCallback={() => {
                         toggleRenameModal();
                         setDataForUpdate({ title: channel.name, id: channel.id });
                       }
                       }
                       deleteCallback={setchannelIdForDelete}
                       deleteModalCallback={setModalDeleteStatus}
          />

      });
    });
  }
  return { channelsToPush, amount };
};

