import {Label, Modal } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"

import PasswordCustomValidation
  from "../../../components/datapages/userspage/passwordCustomValidation/validationComponent"

import chevron from "../../../assets/default/images/ico/chevronForSelect.png"

import "./user.page.scss"

export default function CreateUserModal({
                                          createModalStatus, setCreateModalStatus,
                                          tog_create_modal, editFormInputs,
                                          rolesArr, saveChanges,
                                          validation,emailErrorStatus,
                                          phoneErrorStatus
                                        }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const {
    isLengthValid, isUpperLetterValid,
    isLowerLetterValid, isAnyNumberValid,
    isStringAlphaNumValid, matchStatus,
    formValid
  } = validation

  return (
    <Modal
      isOpen={createModalStatus}
      toggle={() => {
        tog_create_modal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          User Constructor
        </h5>
        <button
          type="button"
          onClick={() => {
            setCreateModalStatus(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <AvForm>
        <div className="modal-body">
          <div className="mb-3">
            <AvField
              name="fullname"
              onChange={editFormInputs}
              label="Fullname  "
              placeholder="Type first name and last name"
              type="text"
              errorMessage="Enter name"
              validate={{ required: { value: true } }}
            />
          </div>
          <div className="mb-3">
            <AvField
              name="email"
              label="E-Mail"
              onChange={editFormInputs}
              placeholder="Enter Valid Email"
              type="email"
              errorMessage="Invalid Email"
              validate={{
                required: { value: true },
                email: { value: true }
              }}
            />
            {emailErrorStatus &&
            <small style={{ color: "red" }}>User with this email exists
            </small>}
          </div>
          <div className="mb-3">
            <Label>Password</Label>
            <div className="pass-input-wrapper">
              <AvField
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                onChange={editFormInputs}
                id="modal-pass-1"
                placeholder="Password"
                errorMessage="Enter password"
                onFocus={(e) => {
                  const el = e.target
                  setTimeout(() => {
                    el.selectionStart = e.target.value.length
                    el.selectionEnd = e.target.value.length
                  }, 10)
                }}
                validate={{ required: { value: true } }}
              />
              {isPasswordVisible && (
                <i
                  className="mdi mdi-eye-outline create-user-pass-icon"
                  style={{ fontSize: "1.5rem" }}
                  onClick={(e) => {
                    const el = document.getElementById("modal-pass-1")
                    el.focus()
                    setIsPasswordVisible(false)
                  }}
                />
              )}
              {!isPasswordVisible && (
                <i
                  className="mdi mdi-eye-off-outline create-user-pass-icon"
                  style={{ fontSize: "1.5rem" }}
                  onClick={(e) => {
                    const el = document.getElementById("modal-pass-1")
                    el.focus()
                    setIsPasswordVisible(true)
                  }}
                />
              )}
            </div>

          </div>
          <div className="mb-3">
            <Label>Confirm password</Label>
            <div className="pass-input-wrapper">
              <AvField
                name="password1"
                type={isPasswordVisible ? "text" : "password"}
                onChange={editFormInputs}
                placeholder="Re-type Password"
                id="modal-pass-2"
                onFocus={(e) => {
                  const el = e.target
                  setTimeout(() => {
                    el.selectionStart = e.target.value.length
                    el.selectionEnd = e.target.value.length
                  }, 10)
                }}
                errorMessage="Enter Re-password"
                validate={{
                  required: { value: true },
                  match: { value: "password" }
                }}
              />
              {isPasswordVisible && (
                <i
                  className="mdi mdi-eye-outline create-user-pass-icon"
                  style={{ fontSize: "1.5rem" }}
                  onClick={(e) => {
                    const el = document.getElementById("modal-pass-2")
                    el.focus()
                    setIsPasswordVisible(false)
                  }}
                />
              )}
              {!isPasswordVisible && (
                <i
                  className="mdi mdi-eye-off-outline create-user-pass-icon"
                  style={{ fontSize: "1.5rem" }}
                  onClick={(e) => {
                    const el = document.getElementById("modal-pass-2")
                    el.focus()
                    setIsPasswordVisible(true)
                  }}
                />
              )}
            </div>

          </div>
          <PasswordCustomValidation
            activatePasswordFields={true}
            isLengthValid={isLengthValid}
            isUpperLetterValid={isUpperLetterValid}
            isLowerLetterValid={isLowerLetterValid}
            isAnyNumberValid={isAnyNumberValid}
            isStringAlphaNumValid={isStringAlphaNumValid}
            matchStatus={matchStatus}
          />
          <div className="mb-3">
            <AvField
              name="phone"
              onChange={editFormInputs}
              label="Phone number  "
              placeholder="Type phone number"
              errorMessage="Incorrect phone number"
              validate={{
                required: { value: true },
                pattern: {
                  value: "^([+\\d].*)?\\d$",
                  errorMessage: "Only Digits and +"
                }
              }}
            />
            {phoneErrorStatus &&
            <small style={{ color: "red" }}>User with this phone number exists
            </small>}
          </div>
          <div className="mb-3 chevron-container">
            <img src={chevron} className="chevron-img-user-page in-modal" alt="" />
            <AvField
              name="role"
              onChange={editFormInputs}
              label="Pick a role"
              placeholder="Choose role"
              type="select"
              errorMessage="Choose Role"
              validate={{
                required: { value: true }
              }}
              className="warning"
            >
              <option></option>
              {" "}
              {rolesArr?.map((role, index) => {
                if (role.is_active) {
                  const string = role.name

                  return (
                    <option value={role._id} key={"role4" + index + role._id}>
                      {string.charAt(0).toUpperCase() +
                      string.slice(1)}
                    </option>
                  )
                }
              })}
            </AvField>
          </div>
          <div className="mb-3">
            <AvField
              onChange={editFormInputs}
              name="notes"
              label="Notes  "
              placeholder="Add note (not required)"
              type="textarea"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_create_modal()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            disabled={!formValid}
            type="submit"
            className="btn btn-primary waves-effect waves-light"
            onClick={saveChanges}
          >
            Create User
          </button>
        </div>
      </AvForm>
    </Modal>
  )

}
