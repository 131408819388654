import { SET_NAV_VALUES } from "./actionTypes"
import config from "../../config/index";



const initialState =
{mainTitle:'',title1:'',title2:''}


const navReducer = (state=initialState,action)=>{
  switch (action.type) {
    case SET_NAV_VALUES:
      state=action.payload.state
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default navReducer
