import {
  Alert,
  Button,
  Container,
} from "reactstrap"

import React, { useState,useEffect } from "react"

import { setNavValues } from "../../../store/nav_breadcrumbs/actions"
import { Table } from "../../../components/shared/reactTable"
import { columnsContentActivities, getAllActivities } from "./activities.table"
import { useSelector } from "react-redux"
import useThrottle from "../../../services/shared_handlers/debouncer"
import { setSortStateAc } from "../../../store/table/sort/actions"
import { Styles } from "../user/user_table"
import config from "../../../config"

export default function ActivitiesPage() { 

  //useState block  
  const [alertStatusWarn, setAlerWarnStatus] = useState(false)
  const [alertText, setAlertText] = useState("")
  const [data, setData] = React.useState([])
  const [pageCount, setPageCount] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [realNameFilter, setRealNameFilter] = useState("")
  const [actionFilter, setActionFilter] = useState("")
  const [actionFilterInputValue, setActionFilterInputValue] = useState("")
  const [usernameFilterInputValue, setUsernameFilterInputValue] = useState("")
  const [activateFilterStatus, setActivateFilterStatus] = useState(false)

  const sortVal = useSelector(state => state.sort.sortBy)
  const [debounce] = useThrottle(1000)

  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]))
  }, [])

  //fill table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true)
    const startRow = pageSize * pageIndex
    const { activitiessToPush, activitiesAmount } = await getAllActivities(
      startRow, pageSize,
      sortVal, realNameFilter,actionFilter)
    setData(activitiessToPush)
    setPageCount(Math.ceil(activitiesAmount / pageSize))
    setLoading(false)
  }, [
    sortVal, realNameFilter,actionFilter,
     activateFilterStatus
  ])

  //filter handlers
  const nameFilter = e => {
    setUsernameFilterInputValue(e.target.value)
    debounce(() => () => setRealNameFilter(e.target.value))
  }
  const actionTypeFilter = e => {
    setActionFilterInputValue(e.target.value)
    debounce(() => () => setActionFilter(e.target.value))
  }

  ///clear all filter
  const resetFilters = () => {
    setRealNameFilter("")
    setActionFilter('')
    setUsernameFilterInputValue('')
    setActionFilterInputValue('')
    setActivateFilterStatus(false)
  }

  //useeffectsect
  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({mainTitle:config.breadCrumbsFirstTitle,title2:'Activities',title1:''}))
  }, [])


  return(
    <Container fluid className="providers-main-wrapper">
      <Styles className="provider-white-card">
        <div className="card-title-desc user-table-filter-block mb-3">
          <div>
            <div className="col-md-12 d-flex">
              <input
                // ref={inputTitle}
                className="form-control sub-inputs me-3"
                placeholder="Search by username"
                type="text"
                onChange={nameFilter}
                value={usernameFilterInputValue}
              />
              <input
                // ref={inputTitle}
                className="form-control sub-inputs"
                placeholder="Search by action"
                type="text"
                onChange={actionTypeFilter}
                value={actionFilterInputValue}
              />
            </div>
          </div>
          <div className="clear-button me-5">
            <Button
              color="secondary"
              className="waves-effect waves-light"
              onClick={resetFilters}
            >
              Clear Filters
            </Button>
          </div>
        </div>
        <Table
          columns={columnsContentActivities}
          data={data}
          pageCount={pageCount}
          fetchData={fetchData}
          loading={loading}
          onSort={onSortedChange}
          filterFields={{realNameFilter,actionFilter}}
        />
      </Styles>
      {alertStatusWarn && (
        <div className="alert-wrapper">
          <Alert color="warning" className="bg-warning text-white success-custom alert-card">
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  )

}