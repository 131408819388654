import { Alert, CardBody, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import { Styles } from '../user/user_table';
import { columnsContent, getAllPlaylists } from './playlist.table';
import { useSelector } from 'react-redux';

import { setSortStateAc } from '../../../store/table/sort/actions';
import useThrottle from '../../../services/shared_handlers/debouncer';
import { useHistory } from 'react-router-dom';
import { deletePlaylistReq } from '../../../services/axios/axios.playlists';
import SweetAlert from 'react-bootstrap-sweetalert';
import { setNavValues } from '../../../store/nav_breadcrumbs/actions';
import { Table } from '../../../components/shared/reactTable';
import config from '../../../config';
import ClearFilterBtn from '../../../components/shared/ClearFilterBtn';
import { typesForStatsComponent } from '../../../components/Stats/helper';
import Stats from '../../../components/Stats';


export default function PlaylistPage() {

  //state block
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [realNameFilter, setRealNameFilter] = React.useState('');
  const [realOwnerFilter, setRealOwnerFilter] = React.useState('');
  const [activateFilterStatus, setActivateFilterStatus] = React.useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertStatusWarn, setAlertStatusWarn] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [magicRender, setMagicRender] = useState(false);
  const [playlistIdForDelete, setPlaylistIdForDelete] = useState(false);
  const [modalDeleteStatus, setModalDeleteStatus] = useState(false);
  const [forcedUpdate, setForcedUpdate] = useState(false);
  const [stats, setStats] = useState({
    createdAtLastWeek: 0,
    createdAtLastMonth: 0,
    playlists: 0,
    linkPagesTotal: 0
  });
  //consts
  const history = useHistory();
  const [debounce] = useThrottle(1000);
  const inputTitle = useRef(null);
  const inputOwner = useRef(null);
  const columns = React.useMemo(
    () => columnsContent,
    []
  );
  //select sort value
  const sortVal = useSelector(state => state.sort.sortBy);

  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]));
  }, []);

  //filters
  const nameFilter = (e) => {
    debounce(() => () => setRealNameFilter(e.target.value));
  };

  const ownerFilter = (e) => {
    debounce(() => () => setRealOwnerFilter(e.target.value));
  };

  const resetFilters = () => {
    inputTitle.current.value = '';
    setRealNameFilter('');
    inputOwner.current.value = '';
    setRealOwnerFilter('');
    setActivateFilterStatus(false);
  };

  //async block

  //delete
  const deletePlaylist = async () => {
    const response = await deletePlaylistReq(playlistIdForDelete);
    if (response.data === 'deleted') {
      setAlertText('Playlist deleted');
    } else {
      setAlertText('Something went wrong. Please contact administrator');
    }
    setModalDeleteStatus(false);
    setMagicRender(!magicRender);
    setAlertStatusWarn(true);
    setAlertText('Playlist deleted');
    setTimeout(() => {
      setAlertStatusWarn(false);
    }, 2000);
    setForcedUpdate(true);
  };

  //fill table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true);
    const startRow = pageSize * pageIndex;
    const data = await getAllPlaylists(
      startRow, pageSize,
      sortVal, realNameFilter, realOwnerFilter,
      activateFilterStatus,
      setPlaylistIdForDelete, setModalDeleteStatus,
      history
    );
    if (!data) {
      return;
    }
    const {
      playlistsToPush,
      amount
    } = data;
    const serverData = playlistsToPush;
    setData(serverData);
    setPageCount(Math.ceil(amount / pageSize));
    setLoading(false);
    setForcedUpdate(false);
  }, [sortVal, realNameFilter, realOwnerFilter, activateFilterStatus, magicRender, forcedUpdate
  ]);

  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title2: 'Playlists' }));
  }, []);

  return (
    <Container fluid className='providers-main-wrapper'>
      {modalDeleteStatus ? (
        <SweetAlert
          title='Attention'
          success={false}
          showCancel
          confirmBtnText='No'
          cancelBtnText='Delete'
          confirmBtnBsStyle='success'
          cancelBtnBsStyle='warning'
          onConfirm={() => {
            setModalDeleteStatus(false);
          }}
          onCancel={() => {
            deletePlaylist();
          }}
        >
          Are you sure want to delete this playlist?
        </SweetAlert>
      ) : null}
      <Styles className='provider-white-card'>
        <Row className='pt-0 mt-4 row-all-content'>
          <Stats type={typesForStatsComponent.playlists} magicRender={magicRender} />
          <Col className='col-table-actions'>
            <CardBody className='p-0'>
              <Row className={'ps-2 '}>
                <Col sm={12} md={8} lg={8} xl={6}>
                  <Row className={'d-flex justify-content-start gap-1'}>
                    <Col xl={3} lg={5} md={6} sm={12}>
                      <input
                        ref={inputTitle}
                        className='quidzi-input-borderered'
                        placeholder='Search by title'
                        type='text'
                        onChange={nameFilter}
                      />
                    </Col>
                    <Col xl={3} lg={5} md={6} sm={12}>
                      <input
                        ref={inputOwner}
                        className='quidzi-input-borderered'
                        placeholder="Search by owner's email"
                        type='text'
                        onChange={ownerFilter}
                      />
                    </Col>
                    <Col xl={3} lg={5} md={6} sm={12}>
                      <ClearFilterBtn resetFilters={resetFilters} />
                    </Col>
                  </Row>
                </Col>
              </Row>

            </CardBody>
          </Col>
        </Row>
        <Row className='hide-scrollbar row-table-content'>
          <Col>
            <Table
              columns={columns}
              data={data}
              pageCount={pageCount}
              fetchData={fetchData}
              loading={loading}
              onSort={onSortedChange}
              filterFields={{ realNameFilter, realOwnerFilter }}
            />
          </Col>
        </Row>

      </Styles>
      {alertStatus && (
        <div className='alert-wrapper'>
          <Alert color='success' className='bg-success text-white success-custom alert-card'>
            <strong>Well done!</strong> {alertText}
          </Alert>
        </div>
      )}
      {alertStatusWarn && (
        <div className='alert-wrapper'>
          <Alert color='warning' className='bg-warning text-white success-custom alert-card'>
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  );
}
