export const stopNoSortCellSorting = (columnsContent, accessor) => {
  if (!accessor) return false;
  const targetColumn = columnsContent.find(column => column.accessor === accessor);
  return targetColumn?.cannotBeSorted;
};

export function generateRandomUUID() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let uuid = '';

  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uuid += characters.charAt(randomIndex);
  }

  return uuid;
}
