import React, { useState } from 'react';
import { getAllUsersTestReq } from '../../../services/axios/axios.user';
import moment from 'moment';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { changeRoleReq } from '../../../services/axios/axios.roles';
import { stopNoSortCellSorting } from '../../../services/table-helpers';
import ActionsCell from '../../../components/shared/ActionsCell';

export const columnsContent = [
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Role',
    accessor: 'role'
  },
  {
    Header: 'Date Created',
    accessor: 'date'
  },
  {
    Header: 'Last Login',
    accessor: 'loginDate',
    cannotBeSorted: true
  },
  {
    Header: 'Phone number',
    accessor: 'phone'
  },
  {
    Header: 'Status',
    accessor: 'deactivations',
    cannotBeSorted: true
  },
  {
    Header: 'Action',
    accessor: 'action',
    cannotBeSorted: true
  }
];

export const getAllUsersForTable = async (skip, limit,
                                          sortState, realNameFilter,
                                          realPhoneFilter, realEmailFilter, realRoleFilter,
                                          activateFilterStatus, goUserEditPage,
                                          deactivateUser, setUserIdForDelete,
                                          setModalDeleteStatus, rolesArr, magicRender,
                                          setMagicRender) => {


  if (sortState?.id && sortState?.id === 'initial') {
    const usersForPush = [];
    const amount = 0;
    return { usersForPush, amount };
  }
  if (stopNoSortCellSorting(columnsContent, sortState?.id)) {
    return;
  }
  let results = await getAllUsersTestReq(
    skip, limit,
    sortState, realNameFilter,
    realPhoneFilter, realEmailFilter,
    realRoleFilter, activateFilterStatus);

  const usersFromBack = results?.data.usersArr;

  const amount = results?.data?.amountG;
  const usersForPush = [];
  if (usersFromBack && usersFromBack?.length) {

    usersFromBack.map((user, index) => {
      usersForPush.push({
        email: (
          <div
            className={user.is_active ? '' : 'inactive'}
            key={'email-' + user.id}
          >
            {user.email}
          </div>
        ),
        name: (
          <div
            className={user.is_active ? '' : 'inactive'}
            key={'name-' + user.id}
          >
            {user?.username}
          </div>
        ),
        role: (
          <RoleCell
            key={'rolecell' + user.id}
            user={user}
            rolesArr={rolesArr}
            magicRender={magicRender}
            setMagicRender={setMagicRender}
          />
        ),
        date: (
          <div
            className={user.is_active ? '' : 'inactive'}
            key={'date-' + user.id}
          >
            {moment(user?.created).format('MM/DD/YYYY')}
          </div>
        ),
        loginDate: (
          <div
            className={user.is_active ? '' : 'inactive'}
            key={'date2-' + user.id}
          >
            {user?.login ? moment(user?.login).format('MM/DD/YYYY') : '-'}
          </div>
        ),
        phone: (
          <div
            searchvalue={user?.phone}
            className={user.is_active ? '' : 'inactive'}
            key={'phone-' + user.id}
          >
            {user?.phone?.length === 36 ? '-' : user?.phone}
          </div>
        ),
        deactivations: (
          <div
            searchvalue={user.is_active.toString()}
            className={user.is_active ? '' : 'inactive'}
            key={'phone-' + user.id}
          >
            {user.is_active ? 'active' : 'deactivated'}
          </div>
        ),
        action: <ActionsCell item={user}
                             editCallback={goUserEditPage}
                             reactivateCallback={() => {
                               deactivateUser(user.id);
                             }}
                             deleteCallback={setUserIdForDelete}
                             deleteModalCallback={setModalDeleteStatus}
        />
      });
    });
  }
  return { usersForPush, amount };
};
export const Styles = styled.div`
  padding: 1rem;

  table {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #222434;
    border-spacing: 0;
    width: 100%;
    background-color: white;

    thead {
      border-top: none;

      tr {
        background-color: white;
        border-top: none;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #585858;
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #E9EAF9;
      text-align: left;

      button {
        padding-left: 0;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    nav {
      padding: 0.5rem;
      padding-left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: max-content;

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }
`;
const RoleCell = ({ user, rolesArr, magicRender, setMagicRender }) => {
  const [popoverright, setpopoverright] = useState(false);
  //role change handler
  const changeRole = async e => {
    setpopoverright(false);
    const userID = e.target.dataset.id;
    const roleID = e.target.dataset.name;
    const response = await changeRoleReq(roleID, userID);
    if (response?.data == 'OK') {
      setMagicRender(!magicRender);
    } else {
      alert('something went wrong');
    }
  };

  return (
    <div className={user.is_active ? 'ps-0' : 'ps-0 inactive'} key={'role-' + user.id}>
      <Dropdown
        direction='left'
        isOpen={popoverright}
        toggle={() => setpopoverright(!popoverright)}
        className='ps-0'
      >
        <DropdownToggle className='btn btn-secondary' caret>
          <div
            className={user.is_active ? 'ps-0' : 'inactive ps-0'}
            key={'name-' + user.id}
            style={{ textAlign: 'left' }}
          >
            {user?.role?.title}
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {rolesArr?.map((role, index) => {
            const string = role?.title;
            return (
              <DropdownItem
                data-name={role.id}
                data-id={user?.id}
                key={'role2' + index + role.id}
                className='drop-option mb-1'
                onClick={changeRole}
                style={{ textAlign: 'center' }}
              >
                {string.charAt(0).toUpperCase() + string.slice(1)}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};


