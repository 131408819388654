import { SORT } from "./actionTypes"

const initialState = {
sortBy:{id:'initial',desc:false}
}

const sort = (state=initialState,action)=>{
  switch (action.type) {
    case SORT:
      state={...state,
        sortBy: action.payload.state
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default sort