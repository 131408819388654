import { statsIconTypes } from '../shared/StatCard';
import { statsForUsersPageReq } from '../../services/axios/axios.user';
import { statsForPlPageReq } from '../../services/axios/axios.playlists';
import { statsForLibPageReq } from '../../services/axios/axios.library-components';
import { statsForChannelsPageReq } from '../../services/axios/axios.channels';

export const typesForStatsComponent = {
  users: 'users',
  library: 'library',
  channels: 'channels',
  playlists: 'playlists'
};

export const dataSplitterForStatsComponent = (type) => {
  switch (type) {
    case typesForStatsComponent.users:
      return {
        first: statsIconTypes.userTotal,
        second: statsIconTypes.userBlocked,
        week: statsIconTypes.userWeek,
        month: statsIconTypes.userMonth,
        title1: 'Total',
        title3: 'Last week',
        title4: 'Last month',
        title2: 'Deactivated'
      };
    case typesForStatsComponent.playlists:
      return {
        first: statsIconTypes.playlistTotal,
        second: statsIconTypes.linkPages,
        week: statsIconTypes.sharedWeek,
        month: statsIconTypes.sharedMonth,
        title1: 'Total',
        title3: 'Last week',
        title4: 'Last month',
        title2: 'Linked blocks in playlists'
      };
    case typesForStatsComponent.channels:
      return {
        first: statsIconTypes.channelsTotal,
        second: statsIconTypes.playlistTotal,
        week: statsIconTypes.sharedWeek,
        month: statsIconTypes.sharedMonth,
        title1: 'Total',
        title3: 'Last week',
        title4: 'Last month',
        title2: 'Playlists in channel'
      };
    default:
      return {
        first: statsIconTypes.libraryTotal,
        // second: statsIconTypes.linkPages,
        week: statsIconTypes.sharedWeek,
        month: statsIconTypes.sharedMonth,
        title1: 'Total',
        title3: 'Last week',
        title4: 'Last month',
        title2: 'Linked blocks in playlists'
      };
  }
};

export const attributeChooser = (type) => {
  switch (type) {
    case typesForStatsComponent.users:
      return {
        1: 'users',
        2: 'deactivatedUsers',
        3: 'createdAtLastWeek',
        4: 'createdAtLastMonth'
      };
    case typesForStatsComponent.playlists:
      return {
        1: 'playlists',
        2: 'linkPagesTotal',
        3: 'createdAtLastWeek',
        4: 'createdAtLastMonth'
      };

    case typesForStatsComponent.channels:
      return {
        1: 'channelsTotal',
        2: 'playlistInChannels',
        3: 'createdAtLastWeek',
        4: 'createdAtLastMonth'
      };
    default:
      return {
        1: 'libraryComponents',
        2: 'linkPagesTotal',
        3: 'createdAtLastWeek',
        4: 'createdAtLastMonth'
      };
  }
};

export const requestForStatsChooser = (type) => {
  switch (type) {
    case typesForStatsComponent.users:
      return statsForUsersPageReq;
    case typesForStatsComponent.playlists:
      return statsForPlPageReq;
    case typesForStatsComponent.channels:
      return statsForChannelsPageReq;
    default:
      return statsForLibPageReq;
  }
};
