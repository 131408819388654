import axios from "axios"

import config from "../../config/index"

//get roles with server side actions
export async function getAllChannelsReq(
  skip,
  limit,
  sortVal,
  realNameFilter,
  realOwnerFilter,
  activateFilterStatus
) {
  try {
    const response = await axios.get(
      config.axiosConnectionUrl +
        "channels/" +
        "table?skip=" +
        skip +
        "&limit=" +
        limit +
        "&sortid=" +
        sortVal?.id +
        "&sortdesc=" +
        (sortVal?.desc ? "DESC" : "ASC") +
        "&namefilter=" +
        realNameFilter +
        "&ownerfilter=" +
        realOwnerFilter +
        "&activated=" +
        activateFilterStatus
    )

    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message
  }
}
export async function deleteChannelReq(id) {
  try {
    const response = await axios.delete(
      config.axiosConnectionUrl + "channels/" + id
    )
    return response
  } catch (e) {
    return e
  }
}

export async function getChannelReq(id) {
    const response = await axios.get(config.axiosConnectionUrl + 'channels/' + `${id}`);
    return response;
}


export async function updateChannelReq(id, data) {
  const response = await axios.put(
    config.axiosConnectionUrl + "channels/" + `${id}`,
    data
  )
  return response
}

export async function statsForChannelsPageReq() {
  try {
    const response = await axios.get(config.axiosConnectionUrl + 'stats/channels');
    return response;
  } catch (error) {
    console.log(error);
    // return error.response.data.message;
  }
}
