import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const tooltipFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TooltipShell = styled.div`
  position: relative;
  width: auto;
  height: auto;
  ${({ forChannels }) => (forChannels ? 'top: 2px;' : '')}
  ${({ forUPV }) => (forUPV ? `
  margin-bottom:8px;` : '')}
  ${({ moveLeft }) => (moveLeft ? `left: ${(moveLeft)}px;` : '')}
`;

const TooltipContainer = styled.div`
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  visibility: hidden;
  position: absolute;
  padding: 0 10px 1px 10px;
  height: 25px;
  background: #585858;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;

  ${({ direction }) => (direction === 'up' ? `
    top: 41px;
  ` : '')}
  ${({ direction }) => (direction === 'down' ? `
    top: -35px;
  ` : '')}
}
`;

const Item = styled.div`
  &:hover ${TooltipContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    animation: ${tooltipFadeIn} 0.5s;
    -webkit-animation: ${tooltipFadeIn} 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: ${tooltipFadeIn} 0.5s; /* Firefox < 16 */
    -o-animation: ${tooltipFadeIn} 0.5s; /* Opera < 12.1 */
  }
`;

const TooltipText = styled.div`
  text-align: center;
  width: auto;
  max-width: 134px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TrianglePointer = styled.div`
  position: absolute;
  background: inherit;
  border: inherit;
  width: 10px;
  height: 10px;
  border-bottom-left-radius: 2px;

  ${({ pointingDirection }) => (pointingDirection === 'up' ? `
    top: -5px;
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
  ` : '')}
  ${({ pointingDirection }) => (pointingDirection === 'down' ? `
    top: calc(25px - 6px);
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
  ` : '')}
  ${({ pointingDirection }) => (pointingDirection === 'left' ? `
    top: calc(25px/2 - 5px);
    left: -4px;
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(45deg);
  ` : '')}
  ${({ pointingDirection }) => (pointingDirection === 'right' ? `
    top: calc(25px/2 - 5px);
    right: -5px;
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(225deg);
  ` : '')}
`;

const Tooltip = ({
                   text,
                   direction,
                   place,
                   isShow = true,
                   moveTop,
                   forChannels,
                   ...props
                 }) => {
  return (
    <TooltipShell
      data-cy={text}
      moveTop={moveTop}
      isLib={place === 'LibraryTabsTag'}
      forChannels={forChannels}
      forUPV={props.forUPV}
      moveLeft={props.moveLeft}
    >
      <Item>
        {props.children}
        {isShow && (
          <TooltipContainer data-tooltip="tooltip" place={place} direction={direction}>
            <TooltipText>
              {text}
            </TooltipText>
            <TrianglePointer pointingDirection={direction} />
          </TooltipContainer>
        )}
      </Item>
    </TooltipShell>
  );
};

export default Tooltip;
