import React, { useState } from 'react';
import { CardFooter, CardText, Modal } from 'reactstrap';
import { updatePlaylistReq } from '../../services/axios/axios.playlists';
import { updateLibraryComponentReq } from '../../services/axios/axios.library-components';
import { updateChannelReq } from '../../services/axios/axios.channels';
import { AvField, AvForm } from 'availity-reactstrap-validation';
//entities available to be renamed
export const renameEntities = {
  'Library Item': 'Library Item',
  'Playlist': 'Playlist',
  'Channel': 'Channel'
};

const reqSwitcher = (entityType) => async (id, title) => {
  switch (entityType) {
    case renameEntities['Library Item']:
      return await updatePlaylistReq(id, { title });
    case renameEntities['Playlist']:
      return await updateLibraryComponentReq(id, { title });
    case renameEntities['Channel']:
      return await updateChannelReq(id, { name: title });
  }
};
const RenameModal = ({ isOpen,
                       toggleModal,
                       entityType,
                       setSucAlertsStatus,
                       setErrAlertStatus,
                       recipientName,
                       recipientId }) => {
  const [theName, setTheName] = useState(recipientName || '');

  const editFormInputs = e => {
    setTheName(e.target.value);
  };
  const updateEntity = async () => {
    if (!theName.trim()) {
      return;
    }
    try {
      const response = await reqSwitcher(entityType)(recipientId, theName);
      if (response?.data === 'OK') {
        setSucAlertsStatus(true);
      } else {
        setSucAlertsStatus(true);
        setTimeout(() => {
          setErrAlertStatus(false);
        }, 3000);
      }
      toggleModal()
    } catch (err) {
      console.log('update error: ' + err);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      centered={true}
    >
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Rename</h5>
        <button
          type='button'
          onClick={toggleModal}
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <CardText className='mb-0'>
          <div className='modal-body'>
            <AvForm>
              <div className='mb-3'>
                <AvField
                  name='fullname'
                  onChange={editFormInputs}
                  value={theName}
                  label='Title  '
                  className={`quidzi-input-borderered`}
                  placeholder='Type title'
                  type='text'
                />
              </div>
              <div className='modal-footer'>
              </div>
            </AvForm>
          </div>
        </CardText>
        <CardFooter className='back-update-wrapper d-flex gap-3'>
          <button
            type='button'
            onClick={() => {
              history.goBack();
            }}
            className='quidzi-btn-dark'
            data-dismiss='modal'
          >
            Back
          </button>
          <button
            type='button'
            className='quidzi-btn-light'
            onClick={updateEntity}
          >
            Update {renameEntities[entityType]}
          </button>
        </CardFooter>
      </div>
    </Modal>
  );
};

export default RenameModal;
