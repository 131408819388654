import { usePagination, useSortBy, useTable } from "react-table"
import React from "react"
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import moment from "moment"

import { getAllRolesReq } from "../../../services/axios/axios.roles"

//table columns
export const columnsContentRoles = [
  {
    Header: "ID",
    accessor: "_id",
    cannotBeSorted: true,
  },
  {
    Header: "Title",
    accessor: "title",
    cannotBeSorted: true,
  },
  {
    Header: "Total User Connections",
    accessor: "description",
    cannotBeSorted: true,
  },
]

///request for data
export const getRoles = async (skip, limit,
                               sortVal, realNameFilter,
                               activateFilterStatus,
) => {
  let results = await getAllRolesReq(
    skip, limit,
    sortVal, realNameFilter,
    activateFilterStatus
  )
  const rolesFromBack = results?.data?.roles ? results.data.roles : []
  const amount = results?.data.amount ? results.data.amount : 0
  const rolesToPush = []
  if (rolesFromBack && rolesFromBack?.length) {
    rolesFromBack.map(role => {
      rolesToPush.push({
        _id: (
          <div
            // className={role.is_active ? "" : "inactive"}
            key={"id-" + role._id}
          >
            {role.id.slice(0, 10) + "..."}
          </div>
        ),
        title: (
          <div
            // className={role.is_active ? "" : "inactive"}
            key={"name-" + role._id}
          >
            {role?.title}
          </div>
        ),
        description: (
          <div
            // className={role.is_active ? "" : "inactive"}
            key={"desc-" + role._id}
          >
            {role?.userConnection.totalCount}
          </div>
        ),
      })
    })
  }
  return { rolesToPush, amount }
}
