import React from "react"
import moment from "moment"

import { getActivitiesForTableReq } from "../../../services/axios/axios.activities"

//table columns
export const columnsContentActivities = [
  {
    Header: "ID",
    accessor: "_id",
  },
  {
    Header: "User Name",
    accessor: "username",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Activity Type",
    accessor: "activityType",
  },
  {
    Header: "Organization ID",
    accessor: "orgId",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
  },
]

///request for data
export const getAllActivities = async (
  skip,
  limit,
  sortVal,
  realNameFilter,
  actionFilter
) => {
  const response = await getActivitiesForTableReq(
    skip,
    limit,
    sortVal,
    realNameFilter,
    actionFilter
  )

  const activitiesFromBack = response.data.activities?.length
    ? response.data.activities
    : []
  const activitiessToPush = []
  let activitiesAmount = response?.data?.amount
  if (activitiesFromBack && activitiesFromBack?.length) {
    activitiesFromBack.map(activity => {
      const tableOrg = {
        _id: (
          <div key={"id-" + activity._id}>
            {activity._id.slice(0, 10) + "..."}
          </div>
        ),
        username: (
          <div key={"username-" + activity._id}>
            {activity?.user.username}
          </div>
        ),
        action: <div key={"action-" + activity._id}>{activity?.action}</div>,
        activityType: (
          <div key={"activityType-" + activity._id}>
            {activity?.activityType}
          </div>
        ),
        orgId: (
          <div key={"orgId-" + activity._id}>
            {activity?.orgId?.slice(0, 20) ?? ""}
          </div>
        ),
        createdAt: (
          <div key={"date-" + activity._id}>
            {moment(activity.createdAt).format("MM/DD/YYYY")}
          </div>
        ),
      }
      activitiessToPush.push(tableOrg)
    })
  }
  return { activitiessToPush, activitiesAmount }
}