//for cypher request. can be handled on backend with handlers.
// ^ = /
// @^_= separator
export const RealFilterValues = {
  page: 'page',
  component: 'component',
  pdf: 'pdf',
  link: 'link@^_text^html@^_embed_component@^_google_embed_component@^_dropbox_embed_component',
  media: 'image@^_audio@^_video',
  file: 'application^@^_text^plain@^_text^rtf',
  audio: 'audio',
  video: 'video',
  image: 'image'
};
export const calculateType = (pathname) => {
  switch (pathname) {
    case activeExpandPaths['/pages']:
      return RealFilterValues.page;
    case activeExpandPaths['/components']:
      return RealFilterValues.component;
    case activeExpandPaths['/pdf']:
      return RealFilterValues.pdf;
    case activeExpandPaths['/media']:
      return RealFilterValues.media;
    case activeExpandPaths['/links']:
      return RealFilterValues.link;
    case activeExpandPaths['/files']:
      return RealFilterValues.file;
  }
};

export const activeExpandPaths = {
  '/pages': '/pages',
  '/components': '/components',
  '/pdf': '/pdf',
  '/media': '/media',
  '/links': '/links',
  '/files': '/files',
  '/library-components': '/library-components'
};

export const typeObjectForStats = {
  '/pages': 'pages',
  '/components': 'components',
  '/pdf': 'pdf',
  '/media': 'media',
  '/links': 'links',
  '/files': 'files'
};
