import { combineReducers } from "redux"


import  Layout from  './layout/reducer'
import Login from  './auth/login/reducer'
import sort from  './table/sort/reducer'
import nav from './nav_breadcrumbs/reducer'




const rootReducer = combineReducers({
  Layout,
  Login,
  sort,
  nav,
})

export default rootReducer
