import {
  CardText,
  Row,
  Col,
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
 CardFooter
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"

import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"


import { getChannelReq, updateChannelReq } from "../../../../services/axios/axios.channels"

import { setNavValues } from "../../../../store/nav_breadcrumbs/actions"

import "./channel-edit.page.scss"
import { Styles } from "../../user/user_table"
import config from "../../../../config"

import 'react-bootstrap-typeahead/css/Typeahead.css';

const ChannelEditPage = () => {
  //state block
  const [currentID, setCurrentID] = useState("")
  const [alertErrStatus, setAlertErrStatus] = useState(false)
  const [alertSucStatus, setAlertSucStatus] = useState(false)
  const [dataToSend, setDataToSend] = useState({
    name: "",
  })
  const [anyChangesStatus, setAnyChangesStatus] = useState(false)
  const [dataToRender, setDataToRender] = useState({})
  const [errMessageAlert, setErrMessageAlert] = useState("")
//consts
  const history = useHistory()
  const params = useParams()

  const editFormInputs = e => {
    setAnyChangesStatus(true)
    const target = e.target
    const value = target.value
    dataToSend.name = value
    setDataToSend(dataToSend)  }

  ///async handlers


  const updateChannel = async () => {
    if ( !anyChangesStatus) {
      return
    }
    try {
      const response = await updateChannelReq(currentID, dataToSend)
      if (response?.data === "OK") {
        setAlertSucStatus(true)
      } else {
        setAlertErrStatus(true)
        setTimeout(() => {
          setAlertErrStatus(false)
        }, 3000)
      }
    } catch (err) {
      console.log("update error: " + err)
    }
  }


  const getChannel = async id => {
    const response = await getChannelReq(id)
    const channel = response?.data
    if (channel) {
      setDataToRender(channel)
      setDataToSend({
        name: channel.name,
      })
    }
  }

  ///useEffect block
  useEffect(() => {
    let id = params.id
    setCurrentID(id)
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title1: "Channels", title2: "Edit" }))
    getChannel(id)
  }, [])

  //main block
  return (
    <Container className="p-0 user-main-wrapper" fluid>
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <Nav tabs className="nav-tabs-custom">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}

              >
                <span className="d-none d-sm-block">Channel</span>
              </NavLink>
            </NavItem>
          </Nav>

              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <div className="modal-body">
                      <AvForm>
                        <div className="mb-3">
                          <AvField
                            name="fullname"
                            onChange={editFormInputs}
                            value={dataToRender.name}
                            label="Name  "

                            placeholder="Type name"
                            type="text"
                          />
                        </div>
                        <div className="modal-footer">
                        </div>
                      </AvForm>
                    </div>
                  </CardText>
                </Col>
              </Row>
              {alertErrStatus && (
                <Alert
                  color="danger"
                  className="bg-danger text-white mb-0 error-custom-user-edit"
                >
                  <strong>Oops!</strong> {errMessageAlert}
                </Alert>
              )}
              <CardFooter className="back-update-wrapper">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                  className="btn btn-secondary waves-effect me-2"
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={updateChannel}
                >
                  Update Channel
                </button>
              </CardFooter>

        </Container>
        {alertSucStatus && (
          <SweetAlert
            title="Success!"
            success
            showCancel
            confirmBtnText="Return to Channels Page"
            cancelBtnText="Continue"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="warning"
            onConfirm={() => {
              setAlertSucStatus(false)
              history.push("../../channels")
            }}
            onCancel={() => {
              setAlertSucStatus(false)
            }}
          >
            Channel updated
          </SweetAlert>
        )}
      </Styles>
    </Container>


  )
}
export default ChannelEditPage
