import React, { useEffect, useState } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { useLocation } from 'react-router-dom';
import { RealFilterValues } from '../../pages/default/library-components/helpers';
import chevron from '../../assets/default/images/ico/chevronForSelect.png';


const TypeSelector = ({ setExValue, realTypeFilter }) => {

  const location = useLocation();
  const isLib = location.pathname.startsWith('/library-components');
  const isMedia = location.pathname.startsWith('/media');

  const onChangeHandler = (e) => {
    setExValue(e.target.value);
  };


  return (
    <AvForm className={'chevron-container'}>
      <img src={chevron} alt='' className='chevron-img' style={{ top: 11 }} />
      <AvField
        name='type'
        value={realTypeFilter}
        onChange={onChangeHandler}
        type='select'
        className={`quidzi-input-borderered select-quidzi ${realTypeFilter ? '' : 'i-see-grey-color'}`}
      >
        <option value='' >
          Filter by type
        </option>
        {isMedia && <>
          <option value={RealFilterValues.image}>
            Image
          </option>
          <option value={RealFilterValues.video}>
            Video
          </option>
          <option value={RealFilterValues.audio}>
            Audio
          </option>
        </>

        }
        {isLib && <>
          <option value={RealFilterValues.page}>
            Pages
          </option>
          <option value={RealFilterValues.component}>
            Components
          </option>
          <option value={RealFilterValues.pdf}>
            PDF
          </option>
          <option value={RealFilterValues.media}>
            Media
          </option>
          <option value={RealFilterValues.link}>
            Links
          </option>
          <option value={RealFilterValues.file}>
            Files
          </option>
        </>}


      </AvField>
    </AvForm>
  );
};

export default TypeSelector;
