import axios from 'axios';

import config from '../../config/index';

export async function createUser(reqObj) {
  try {
    const response = await axios.post(config.axiosConnectionUrl + 'user/' + 'create', reqObj);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function inviteUserByEmail(reqObj) {
  try {
    const response = await axios.post(config.axiosConnectionUrl + 'user/' + 'invite', reqObj);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function deactivateUserReq(id) {
  try {
    const response = await axios.put(config.axiosConnectionUrl + 'user/' + 'reactivate/' + id);
    return response;
  } catch (e) {
    console.log(e);
    return e;
  }
}

//get all user
export async function getAllUsersTestReq(
  skip,
  limit,
  sortState,
  realNameFilter,
  realPhoneFilter,
  realEmailFilter,
  realRoleFilter,
  activateFilterStatus
) {
  try {
    const response = await axios.get(
      config.axiosConnectionUrl +
      'user/' +
      'table?skip=' +
      skip +
      '&limit=' +
      limit +
      '&sortid=' +
      sortState?.id +
      '&sortdesc=' +
      (sortState?.desc ? 'DESC' : 'ASC') +
      '&namefilter=' +
      realNameFilter +
      '&phonefilter=' +
      realPhoneFilter +
      '&emailfilter=' +
      realEmailFilter +
      '&rolefilter=' +
      realRoleFilter +
      '&activated=' +
      activateFilterStatus
    );

    return response;
  } catch (error) {
    console.log(error.message);
  }
}

//get 1 user
export async function getUserReq(id) {
  const response = await axios.get(config.axiosConnectionUrl + 'user/' + `${id}`);
  return response;
}

//update user

export async function updateUserReq(id, data) {
  const response = await axios.put(config.axiosConnectionUrl + 'user/' + `${id}`, data);
  return response;
}

//attach team to user
export async function attachTeamReq(id, teamID, status) {
  try {
    const response = await axios.put(config.axiosConnectionUrl + 'user/' + 'team/' + id + '/' + teamID, {
      status: status
    });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteUserReq(id) {
  try {
    const response = await axios.delete(config.axiosConnectionUrl + 'user/' + id);
    return response;
  } catch (error) {
    console.log(error);
    // return error.response.data.message;
  }
}

export async function statsForUsersPageReq() {
  try {
    const response = await axios.get(config.axiosConnectionUrl + 'stats/' + 'users');
    return response;
  } catch (error) {
    console.log(error);
    // return error.response.data.message;
  }
}

export async function getUnregisteredUser(token) {
  const response = await axios.get(config.axiosConnectionUrl + `user/new-user/${token}`);
  return response;
}


export async function updateUserOrganizations(reqObj, id) {
  try {
    const response = await axios.post(
      config.axiosConnectionUrl + 'user/' + 'add-initial-orgs/' + id,
      reqObj
    );
    return response;
  } catch (error) {
    console.log(error);
    // return error.response.data.message;
  }
}
