import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'reactstrap';
import StatCard from '../shared/StatCard';
import {
  attributeChooser,
  dataSplitterForStatsComponent,
  requestForStatsChooser,
  typesForStatsComponent
} from './helper';
import { typeObjectForStats } from '../../pages/default/library-components/helpers';


const Stats = ({ type, magicRender }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const splitted = dataSplitterForStatsComponent(type);

  const timeoutRef = useRef(null);

  const setDataHandler = async () => {
    const { data } = await requestForStatsChooser(type)(typeObjectForStats[location.pathname]);
    if (data && Object.keys(data).length >= 3) {
      setData(data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    timeoutRef.current = setTimeout(setDataHandler, 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [magicRender]);

  return (
    <Row className='p-0 ps-4 row-cards-block'>
      <StatCard isLoading={isLoading}
                isFirst
                amount={data[attributeChooser(type)['1']]}
                title={splitted.title1}
                type={splitted.first} />
      {(type === typesForStatsComponent.users ||
        type === typesForStatsComponent.channels ||
        type === typesForStatsComponent.playlists) &&
      <StatCard isLoading={isLoading}
                amount={data[attributeChooser(type)['2']]}
                title={splitted.title2}
                type={splitted.second} />}
      <StatCard isLoading={isLoading}
                amount={data[attributeChooser(type)['3']]}
                title={splitted.title3}
                type={splitted.week} />
      <StatCard isLoading={isLoading}
                amount={data[attributeChooser(type)['4']]}
                title={splitted.title4}
                type={splitted.month} />
    </Row>
  );
};

export default Stats;
