import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Alert, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { apiError, loginUser } from '../../store/actions';
import { signIn } from '../../services/register_flow/log_in_out';
import config from '../../config';
import { LoginBackGround } from './LoginBG';
import'./index.scss'

const Login = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const loginHandler = async (a, b, history) => {
    setLoading(true);
    const result = await signIn(a, b);
    if (result) {
      config.reduxStore.dispatch(loginUser(result));
      history.push('./users');
    } else {
      config.reduxStore.dispatch(apiError('You are not Authorized'));
    }
    setLoading(false);
  };
  return (

        <Container fluid className='justify-content-center'>
          <LoginBackGround/>
          <Row className='justify-content-center pt-5 mt-5'>
            <Col md={4} lg={3} xl={2} className='mt-5 pt-5'>
              <Card className='overflow-hidden mt-5 login-card'>



                <CardBody className={`p-4  `}>
                  <Row className='justify-content-center'>
                    <img src={config.leftSideBarLogo}  alt='logo' />

                  </Row>
                  <div className='p-3'>
                    <AvForm
                      className='form-horizontal mt-4'
                      onValidSubmit={(e, v) => {
                        loginHandler(e, v, history);
                      }}
                    >
                      {props.error && typeof props.error === 'string' ? (
                        <Alert color='danger'>{props.error}</Alert>
                      ) : null}

                      <div className='mb-3'>
                        <AvField
                          name='email'
                          label='Email'
                          placeholder='Enter email'
                          type='email'
                          className='quidzi-input-borderered'
                          required
                        />
                      </div>

                      <div className='mb-3'>
                        <AvField
                          name='password'
                          label='Password'
                          type='password'
                          required
                          className='quidzi-input-borderered'
                          placeholder='Enter Password'
                        />
                      </div>

                      <Row className='mb-3'>
                        <Col sm={12} className='d-flex justify-content-end ps-5'>
                          {!loading && <button
                            className='quidzi-btn-light ms-4 mt-3'
                            type='submit'
                          >
                            Log In
                          </button>}
                          {loading && <Spinner className='ms-5 mt-3' color='warning' />}
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
  );
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
};
