import React from 'react';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';


export const statsIconTypes = {
  userTotal: 'userTotal',
  userBlocked: 'userBlocked',
  userWeek: 'userWeek',
  userMonth: 'userMonth',
  sharedWeek: 'sharedWeek',
  sharedMonth: 'sharedMonth',
  playlistTotal: 'playlistTotal',
  linkPages: 'linkPages',
  libraryTotal: 'libraryTotal',
  libraryPages: 'libraryPages',
  channelsTotal: 'channelsTotal'
};

const IconSwitcher = ({ type }) => {
  switch (type) {
    case statsIconTypes.userWeek:
      return <i className={'mdi-human-queue mdi mdi-36px'} />;
    case  statsIconTypes.userBlocked:
      return <i className={'mdi mdi-block-helper mdi-36px'} />;
    case statsIconTypes.userTotal:
      return <i className={'mdi   mdi-human-child mdi-36px'} />;
    case statsIconTypes.userMonth:
      return <i className={'mdi-human-capacity-increase mdi mdi-36px'} />;
    case statsIconTypes.sharedWeek:
      return <i className={'mdi   mdi-calendar-week mdi-36px'} />;
    case statsIconTypes.sharedMonth:
      return <i className={'mdi-calendar-month mdi mdi-36px'} />;
    case statsIconTypes.playlistTotal:
      return <i className={'mdi   mdi-playlist-play mdi-36px'} />;
    case statsIconTypes.linkPages:
      return <i className={'mdi-play-speed mdi mdi-36px'} />;
    case statsIconTypes.libraryTotal:
      return <i className={'mdi-bookshelf mdi mdi-36px'} />;
    case statsIconTypes.libraryPages:
      return <i className={'mdi-newspaper-variant-outline mdi mdi-36px'} />;
    case statsIconTypes.channelsTotal:
      return <i className={'mdi-hubspot mdi mdi-36px'} />;
  }

};
const StatCard = ({ amount, title, type, isFirst, isLoading }) => {
  return (
    <Col xl={3} md={6} >
      <Card className={`mini-stat bg-warning ${isFirst ? '' : 'ms-1'}`}>
        <CardBody className={'mh-90'}>
          <Row>
            <Col sm={10}>
              <div className='stats-card-title'>{title}</div>
              {!isLoading && <div className='stats-card-data'> {amount}</div>}
              {isLoading && <Spinner color='dark' style={{ width: 20, height: 20 }} />}
            </Col>
            <Col sm={2} className={'d-flex justify-content-end'}><IconSwitcher type={type} /></Col>
          </Row>

        </CardBody>
      </Card>
    </Col>
  );
};

export default StatCard;
