import { useState, useEffect } from 'react';

export default function useThrottle(time = 400) {
  const [timeoutId, setTimeoutId] = useState(0);
  const [callback, setCallback] = useState(() => {});

  useEffect(() => {
    if (callback) {
      if (timeoutId) clearTimeout(timeoutId);
      setTimeoutId(setTimeout( () => {
        callback();
      }, time));
    }
  }, [callback]);

  return [setCallback];
}