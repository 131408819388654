import {
  TabContent,
  TabPane,
  CardText,
  Row,
  Col,
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  Label,
  CardBody,
  Card, CardFooter
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import React, { useEffect,  useState } from "react"
import { useHistory } from "react-router-dom"

import { setNavValues } from "../../../../store/nav_breadcrumbs/actions"
import config from "../../../../config"
import { getAllOrgs } from "../../../../services/axios/axios.organizations"
import { createUser,updateUserOrganizations } from "../../../../services/axios/axios.user"
import { getRolesReq } from "../../../../services/axios/axios.roles"
import PasswordCustomValidation
  from "../../../../components/datapages/userspage/passwordCustomValidation/validationComponent"

import chevron from "../../../../assets/default/images/ico/chevronForSelect.png"
import "../../user-edit/user-edit.page.scss"
import { Styles } from "../user_table"

import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';


const UserCreatePage = () => {


  //state block
  const [roles, setRoles] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [alertErrStatus, setAlertErrStatus] = useState(false)
  const [alertSucStatus, setAlertSucStatus] = useState(false)
  const [userToSend, setUserToSend] = useState({
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    roleID: "",
    notes: "",
    password: "",
    phoneNumber: "",
    is_active: true,
  })
  const [confirmedPassword, setConfirmedPassword] = useState("")
  const [errMessageAlert, setErrMessageAlert] = useState("")
  //validation
  const [isLengthValid, setIsLengthValid] = useState(false)
  const [isUpperLetterValid, setIsUpperLetterValid] = useState(false)
  const [isLowerLetterValid, setIsLowerLetterValid] = useState(false)
  const [isAnyNumberValid, setIsAnyNumberValid] = useState(false)
  const [isStringAlphaNumValid, setIsStringAlphaNumValid] = useState(false)
  const [matchStatus, setMatchStatus] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [emailErrorStatus, setEmailErrorStatus] = useState(false)
  const [phoneErrorStatus, setPhoneErrorStatus] = useState(false)
  const [isFullNameValid, setIsFullNameValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true)
  const [anyChangesStatus, setAnyChangesStatus] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)


  const [currentOrgs, setCurrentOrgs] = useState([])
  const [currentSuborgs, setCurrentSuborgs] = useState([])



//consts
  const history = useHistory()

  const editFormInputs = e => {
    setAnyChangesStatus(true)
    const target = e.target
    const value = target.value
    switch (target.name) {
      case "login":
        userToSend.userName = value
        setUserToSend(userToSend)
        break
      case "fullname":
        setIsFullNameValid(value.trim().length >= 2)
        userToSend.firstName = value.split(" ")[0]
        userToSend.lastName = value.split(" ")[2] ? value.split(" ")[1] + " " + value.split(" ")[2] : value.split(" ")[1]
        userToSend.fullName = value
        setUserToSend(userToSend)
        break
      case "email":
        userToSend.email = value
        setUserToSend(userToSend)
        setIsEmailValid(/^(([^()[\]\\.,;:\s@"]+(\.[^()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          .test(String(e.target.value).toLowerCase()))
        break
      case "role":
        userToSend.roleID = value
        setUserToSend(userToSend)
        break
      case "notes":
        userToSend.notes = value
        setUserToSend(userToSend)
        break
      case "password":
        userToSend.password = value
        setIsLengthValid(value.trim().length >= 8)
        //upperblock
        setIsUpperLetterValid(value.toLowerCase() != value)
        //lower block
        setIsLowerLetterValid(value.toUpperCase() != value)
        //number block
        setIsAnyNumberValid(/\d/i.test(value))
        setIsStringAlphaNumValid(/(?=.*?[#?!@$%^&*-])/.test(value))
        if (!!confirmedPassword) {
          setMatchStatus(value === confirmedPassword)
        }
        setUserToSend(userToSend)
        break
      case "password1":
        setMatchStatus(e.target.value.toString() === userToSend.password.toString())
        setConfirmedPassword(value)
        break
      case "phone":
        userToSend.phoneNumber = value
        setIsPhoneNumberValid(/^([+\d].*)?\d$/.test(value))
        setUserToSend(userToSend)
        break
      default:
        break
    }
  }

  const addUser = async () => {
    if (!formValid || !anyChangesStatus) {
      return
    }
      const response = await createUser(userToSend)
      if (response.data.message === "user created") {
        setAlertSucStatus(true)
        history.push('../../users')

        const reqObj = [...currentOrgs, ...currentSuborgs].map(org => org.id)
        await updateUserOrganizations({ orgs: reqObj }, response.data.id)

      } else if (response.data.message.includes("email")) {
        setEmailErrorStatus(true)
        setTimeout(() => {
          setEmailErrorStatus(false)
        }, 5000)
      } else if (response.data.message.includes("phone")) {
        setPhoneErrorStatus(true)
        setTimeout(() => {
          setPhoneErrorStatus(false)
        }, 5000)
      } else {
        setErrMessageAlert("Something went wrong.")
         setAlertErrStatus(true)
        setTimeout(() => {
          setAlertErrStatus(false)
        }, 3000)
      }
  }

  const getRoles = async () => {
    const response = await getRolesReq()
    setRoles(response?.data.roles)
  }





  ///useEffect block
  useEffect(() => {
    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title1: "Users", title2: "Create" }))
    getRoles()
  }, [])

  useEffect(() => {
    setIsPasswordValid(matchStatus
      && isStringAlphaNumValid
      && isAnyNumberValid
      && isLowerLetterValid
      && isUpperLetterValid
      && isLengthValid)

  }, [
    matchStatus
    , isStringAlphaNumValid
    , isAnyNumberValid
    , isLowerLetterValid
    , isUpperLetterValid
    , isLengthValid
  ])

  useEffect(() => {
    setFormValid(isFullNameValid && isEmailValid && isPasswordValid && isPhoneNumberValid)
  }, [isFullNameValid, isEmailValid, isPasswordValid, isPhoneNumberValid])
  //main block
  return (
    <Container className="p-0 user-main-wrapper ms-0" >
      <Styles className=" styles-inner-pages-wrapper">
        <Container fluid className="edit-user-wrapper hide-scrollbar">
          <Nav tabs className="nav-tabs-custom">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  setActiveTab("1")
                }}
              >
                <span className="d-none d-sm-block">Profile</span>
              </NavLink>
            </NavItem>

          </Nav>
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <div className="modal-body">
                      <AvForm>
                        <div className="mb-3">
                          <AvField
                            name="fullname"
                            onChange={editFormInputs}
                            label="Full name  "
                            className={`quidzi-input-borderered ${isFullNameValid ? "" : "is-invalid"}`}
                            placeholder="Type first name and last name"
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: "Enter Name" },
                              minLength: { value: 2, errorMessage: "At least 2 symbols" }
                            }}

                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className={`quidzi-input-borderered ${isEmailValid ? "" : "is-invalid"}`}
                            onChange={editFormInputs}
                            placeholder="Enter Valid Email"
                            type="email"
                            validate={{
                              required: { value: true, errorMessage: "Enter Email" },
                              email: { value: true, errorMessage: "Invalid Email" }
                            }}
                          />
                          {emailErrorStatus &&
                          <small style={{ color: "red" }}>User with this email exists
                          </small>}
                        </div>
                        <div className="mb-3">
                          <Label>Password
                          </Label>
                          <div className="pass-input-wrapper">
                            <AvField
                              name="password"
                              type={isPasswordVisible ? "text" : "password"}
                              id="modal-pass-1"
                              onChange={editFormInputs}
                              className={`quidzi-input-borderered ${!isPasswordValid ? "is-invalid" : ""}`}
                              onFocus={(e) => {
                                const el = e.target
                                setTimeout(() => {
                                  el.selectionStart = e.target.value.length
                                  el.selectionEnd = e.target.value.length
                                }, 10)
                              }}
                              errorMessage="Enter password"
                              validate={{ required: { value: true } }}
                            />
                            {!isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={(e) => {
                                  const el = document.getElementById("modal-pass-1")
                                  el.focus()
                                  setIsPasswordVisible(true)
                                }}
                              />
                            )}
                            {isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-off-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={(e) => {
                                  const el = document.getElementById("modal-pass-1")
                                  el.focus()
                                  setIsPasswordVisible(false)
                                }}
                              />
                            )}
                          </div>

                        </div>
                        <div className="mb-3">
                          <Label>Confirm Password</Label>
                          <div className="pass-input-wrapper">
                            <AvField
                              name="password1"
                              id="modal-pass-2"
                              type={isPasswordVisible ? "text" : "password"}
                              onChange={editFormInputs}
                              className={`quidzi-input-borderered ${!isPasswordValid ? "is-invalid" : ""}`}
                              onFocus={(e) => {
                                const el = e.target
                                setTimeout(() => {
                                  el.selectionStart = e.target.value.length
                                  el.selectionEnd = e.target.value.length
                                }, 10)
                              }}
                              errorMessage="Enter Re-password"
                              validate={{
                                required: { value: true },
                                match: { value: "password" }
                              }}
                            />
                            {!isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={(e) => {
                                  const el = document.getElementById("modal-pass-2")
                                  el.focus()
                                  setIsPasswordVisible(true)
                                }}
                              />
                            )}
                            {isPasswordVisible && (
                              <i
                                className="mdi mdi-eye-off-outline edit-user-pass-icon"
                                style={{ fontSize: "1.5rem" }}
                                onClick={(e) => {
                                  const el = document.getElementById("modal-pass-2")
                                  el.focus()
                                  setIsPasswordVisible(false)
                                }}
                              />
                            )}
                          </div>

                        </div>
                        <PasswordCustomValidation
                          activatePasswordFields={true}
                          isLengthValid={isLengthValid}
                          isUpperLetterValid={isUpperLetterValid}
                          isLowerLetterValid={isLowerLetterValid}
                          isAnyNumberValid={isAnyNumberValid}
                          isStringAlphaNumValid={isStringAlphaNumValid}
                          matchStatus={matchStatus}
                        />
                        <div className="mb-3">
                          <AvField
                            label="Phone number"
                            name="phone"
                            className="quidzi-input-borderered"
                            onChange={editFormInputs}
                            validate={{
                              required: { value: true, errorMessage: "Enter phone number" },
                              pattern: {
                                value: "^([+\\d].*)?\\d$",
                                errorMessage: "Only Digits and +"
                              }
                            }}
                          />
                          {phoneErrorStatus &&
                          <small style={{ color: "red" }}>User with this phone number exists
                          </small>}
                        </div>
                        <div className="mb-3 chevron-container">
                          <img src={chevron} alt="" className="chevron-img" />
                          <AvField
                            name="role"
                            className="quidzi-input-borderered"
                            onChange={editFormInputs}
                            label="Role  "
                            placeholder="Choose role"
                            type="select"
                            errorMessage="Choose Role"
                            validate={{
                              required: { value: true }
                            }}
                          >
                            <option>

                            </option>
                            {" "}
                            {roles?.map(role => {
                              if (
                                role?.is_active   || true
                              ) {
                                const string = role.title
                                return (
                                  <option value={role._id} key={"option-role" + role.id}>
                                    {string.charAt(0).toUpperCase() +
                                    string.slice(1)}
                                  </option>
                                )
                              }
                            })}
                          </AvField>
                        </div>
                      </AvForm>
                    </div>
                  </CardText>
                </Col>
              </Row>
              {alertErrStatus && (
                <Alert
                  color="danger"
                  className="bg-danger text-white mb-0 error-custom-user-edit"
                >
                  <strong>Oops!</strong> {errMessageAlert}
                </Alert>
              )}
              <CardFooter className="back-update-wrapper d-flex gap-3">
                <button
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                  className="quidzi-btn-dark"
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button
                  type="button"
                  className="quidzi-btn-light"
                  onClick={addUser}
                >
                  Create User
                </button>
              </CardFooter>
            </TabPane>
          </TabContent>
        </Container>
        {alertSucStatus && (
          <SweetAlert
            title="Success!"
            success
            showCancel
            confirmBtnText="Return to Users Page"
            cancelBtnText="Continue"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="warning"
            onConfirm={() => {
              setAlertSucStatus(false)
              history.push("../../users")
            }}
            onCancel={() => {
              setAlertSucStatus(false)
            }}
          >
            User Created
          </SweetAlert>
        )}

      </Styles>
    </Container>


  )
}
export default UserCreatePage
