import { signInReq } from "../../services/axios/axios.auth"
import holdTokens from "../../services/hold_tokens/holdTokenPair"

export async function signIn(e, b) {
  const email = b.email
  const password = b.password

  try {
    localStorage.clear()

    const ipResponse = await fetch("https://api.ipify.org/?format=json")
    const jsonIpRes = await ipResponse.json()

    const reqObj = { email, password, notes: jsonIpRes.ip }

    const response = await signInReq(reqObj)
    if (response === "Unauthorized") {
      return false
    }
    // if (!['super_user', 'admin', 'l1_support', 'l2_support'].includes(response.data.role.name)) {
    //   return false
    // }
    if (response.status === 200) {
      const accessToken = response.data.resp.accessToken

      const refreshToken = response.data.resp.refreshToken
      await holdTokens.setTokens(accessToken, accessToken)

      return true
    }


  } catch (error) {
    localStorage.clear()

    const reqObj = { email, password, notes: "" }

    const response = await signInReq(reqObj)


    if (response === "Unauthorized") {
      return false
    }
    if (response.data.email !== "admin@gmail.com") {
      return false
    }
    if (response.status === 200) {
      const accessToken = response.data.resp.accessToken

      const refreshToken = response.data.resp.refreshToken
      await holdTokens.setTokens(accessToken, refreshToken)

      return true
    }


  }
}
