import { Alert, CardBody, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import { Styles } from '../user/user_table';
import { columnsContent, getLibraryComponents } from './libraryComponents.table';
import { useSelector } from 'react-redux';

import { setSortStateAc } from '../../../store/table/sort/actions';
import useThrottle from '../../../services/shared_handlers/debouncer';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteLibraryComponentReq } from '../../../services/axios/axios.library-components';
import SweetAlert from 'react-bootstrap-sweetalert';
import { setNavValues } from '../../../store/nav_breadcrumbs/actions';
import { Table } from '../../../components/shared/reactTable';
import config from '../../../config';
import ClearFilterBtn from '../../../components/shared/ClearFilterBtn';
import { activeExpandPaths, calculateType } from './helpers';
import Stats from '../../../components/Stats';
import { typesForStatsComponent } from '../../../components/Stats/helper';
import TypeSelector from '../../../components/TypeSelector';


export default function LibraryComponentsPage() {
  //take location and set typeFilter
  const location = useLocation();

  //state block
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [realNameFilter, setRealNameFilter] = React.useState('');
  const [realOwnerFilter, setRealOwnerFilter] = React.useState('');
  const [realTypeFilter, setRealTypeFilter] = React.useState(calculateType(location.pathname) || '');
  const [activateFilterStatus, setActivateFilterStatus] = React.useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertStatusWarn, setAlertStatusWarn] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [magicRender, setMagicRender] = useState(false);
  const [libraryComponentsIdForDelete, setLibraryComponentsIdForDelete] = useState(false);
  const [modalDeleteStatus, setModalDeleteStatus] = useState(false);
  const [forcedUpdate, setForcedUpdate] = useState(false);
  //consts
  const history = useHistory();
  const [debounce] = useThrottle(1000);
  const inputTitle = useRef(null);
  const inputOwner = useRef(null);
  const inputType = useRef(null);
  const columns = React.useMemo(
    () => columnsContent,
    []
  );
  //select sort value
  const sortVal = useSelector(state => state.sort.sortBy);

  //dispatch sorting
  const onSortedChange = React.useCallback((sortBy) => {
    config.reduxStore.dispatch(setSortStateAc(sortBy[0]));
  }, []);

  //filters
  const nameFilter = (e) => {
    debounce(() => () => setRealNameFilter(e.target.value));
  };
  const ownerFilter = (e) => {
    debounce(() => () => setRealOwnerFilter(e.target.value));
  };


  const resetFilters = () => {
    inputTitle.current.value = '';
    inputOwner.current.value = '';
    // inputType.current.value = '';
    setRealNameFilter('');
    setRealOwnerFilter('');
    setRealTypeFilter('');
    setActivateFilterStatus(false);
  };

  //async block

  //delete
  const deleteLibraryComponent = async () => {
    const response = await deleteLibraryComponentReq(libraryComponentsIdForDelete);
    if (response.data === 'deleted') {
      setAlertText('Library Component deleted');
    } else {
      setAlertText('Something went wrong. Please contact administrator');
    }
    setModalDeleteStatus(false);
    setMagicRender(!magicRender);
    setAlertStatusWarn(true);
    setAlertText('Library Component deleted');
    setTimeout(() => {
      setAlertStatusWarn(false);
    }, 2000);
    setForcedUpdate(true);
  };

  //fill table
  const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
    setLoading(true);
    const startRow = pageSize * pageIndex;
    const data = await getLibraryComponents(
      startRow, pageSize,
      sortVal, realNameFilter,
      realOwnerFilter, realTypeFilter,
      activateFilterStatus,
      setLibraryComponentsIdForDelete, setModalDeleteStatus,
      history
    );
    if (!data) {
      return;
    }
    const {
      libraryComponentsToPush,
      amount
    } = data;
    const serverData = libraryComponentsToPush;
    setData(serverData);
    setPageCount(Math.ceil(amount / pageSize));
    setLoading(false);
    setForcedUpdate(false);
  }, [sortVal, realNameFilter, realOwnerFilter, realTypeFilter, activateFilterStatus, magicRender, forcedUpdate
  ]);


  useEffect(() => {

    config.reduxStore.dispatch(setNavValues({ mainTitle: config.breadCrumbsFirstTitle, title2: 'Library Components' }));
  }, []);

  return (
    <Container fluid className='providers-main-wrapper'>
      {modalDeleteStatus ? (
        <SweetAlert
          title='Attention'
          success={false}
          showCancel
          confirmBtnText='No'
          cancelBtnText='Delete'
          confirmBtnBsStyle='success'
          cancelBtnBsStyle='warning'
          onConfirm={() => {
            setModalDeleteStatus(false);
          }}
          onCancel={() => {
            deleteLibraryComponent();
          }}
        >
          Are you sure want to delete this library component?
        </SweetAlert>
      ) : null}
      <Styles className='provider-white-card'>
        <Row className='pt-0 mt-4 row-all-content'>
          <Stats magicRender={magicRender} type={typesForStatsComponent.library} />
          <Row className='p-0 ps-4 row-cards-block'>
          </Row>
          <Col className='col-table-actions'>
            <CardBody className='p-0'>
              <Row className={'ps-2'}>
                <Col className={'d-flex justify-content-start gap-4'} xl={6} md={12}>
                  <Row className={'w-100'}>
                    <Col xl={3} md={6} className={'mb-3'}>
                      <div className='col-md-12'>
                        <input
                          ref={inputTitle}
                          className='quidzi-input-borderered'
                          placeholder='Search by title'
                          type='text'
                          onChange={nameFilter}
                        />
                      </div>
                    </Col>
                    <Col xl={3} md={6} className={'mb-3'}>
                      <div className='col-md-12'>
                        <input
                          ref={inputOwner}
                          className='quidzi-input-borderered'
                          placeholder="Search by owner's email"
                          type='text'
                          onChange={ownerFilter}
                        />
                      </div>
                    </Col>

                    {(location.pathname === activeExpandPaths['/library-components'] || location.pathname === activeExpandPaths['/media']) &&

                    <Col xl={3} md={6} className={'mb-3'}>
                      <div className='col-md-12'>
                        <TypeSelector
                          realTypeFilter={realTypeFilter}
                          setExValue={setRealTypeFilter} />
                      </div>
                    </Col>}
                    <Col xl={3} md={6}> <ClearFilterBtn resetFilters={resetFilters} /></Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>

        <Row className='hide-scrollbar row-table-content'>
          <Col>
            <Table
              columns={columns}
              data={data}
              pageCount={pageCount}
              fetchData={fetchData}
              loading={loading}
              onSort={onSortedChange}
              filterFields={{ realNameFilter, realOwnerFilter, realTypeFilter }}
            />
          </Col>
        </Row>
      </Styles>
      {alertStatus && (
        <div className='alert-wrapper'>
          <Alert color='success' className='bg-success text-white success-custom alert-card'>
            <strong>Well done!</strong> {alertText}
          </Alert>
        </div>
      )}
      {alertStatusWarn && (
        <div className='alert-wrapper'>
          <Alert color='warning' className='bg-warning text-white success-custom alert-card'>
            <strong>Oops!</strong> {alertText}
          </Alert>
        </div>
      )}
    </Container>
  );
}
