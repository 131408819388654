import axios from 'axios';

import config from '../../config/index';

///get all activities for displaying at table
export async function getActivitiesForTableReq(
  skip,
  limit,
  sortVal,
  realNameFilter,
  actionFilter
) {
  try {
    const response = await axios.get(
      config.axiosConnectionUrl +
        "activities" +
        "?skip=" +
        skip +
        "&limit=" +
        limit +
        "&sortid=" +
        sortVal?.id +
        "&sortdesc=" +
        sortVal?.desc +
        "&realnamefilter=" +
        realNameFilter +
        "&actionfilter=" +
        actionFilter 
        
    )
    return response
  } catch (error) {
    console.log(error)
    // return error.response.data.message;
  }
}