import React from 'react';
import moment from 'moment';
import { getAllLibraryComponentsReq } from '../../../services/axios/axios.library-components';
import { stopNoSortCellSorting } from '../../../services/table-helpers';
import ActionsCell from '../../../components/shared/ActionsCell';

//table columns
export const columnsContent = [
  {
    Header: 'Title',
    accessor: 'name'
  },
  {
    Header: 'Owner',
    accessor: 'owner'
  },
  {
    Header: 'Components',
    accessor: 'components',
    cannotBeSorted: true
  },
  {
    Header: 'Type',
    accessor: 'type'
  },

  {
    Header: 'File URL',
    accessor: 'urlFile',
    cannotBeSorted: true
  },
  {
    Header: 'Playback ID',
    accessor: 'playbackID',
    cannotBeSorted: true
  },
  {
    Header: 'Date Created',
    accessor: 'created'
  },
  {
    Header: 'Updated At',
    accessor: 'updated',
    cannotBeSorted: true
  },
  {
    Header: 'Action',
    accessor: 'action',
    cannotBeSorted: true
  }
];

///request for data
export const getLibraryComponents = async (
  skip,
  limit,
  sortVal,
  realNameFilter,
  realOwnerFilter,
  realTypeFilter,
  activateFilterStatus,
  setLibraryComponentsIdForDelete,
  setModalDeleteStatus,
  history
) => {
  if (stopNoSortCellSorting(columnsContent, sortVal?.id)) {
    return;
  }
  let results = await getAllLibraryComponentsReq(
    skip,
    limit,
    sortVal,
    realNameFilter,
    realOwnerFilter,
    realTypeFilter,
    activateFilterStatus
  );

  const libraryComponentsFromBack = results?.data.libraryComponents
    ? results.data.libraryComponents
    : [];
  const amount = results?.data.amountG
    ? results.data.amountG ?? results.data.length
    : 0;
  const libraryComponentsToPush = [];

  if (libraryComponentsFromBack && libraryComponentsFromBack?.length) {
    libraryComponentsFromBack.map(libraryComponent => {
      libraryComponentsToPush.push({
        id: (
          <div key={'id-' + libraryComponent.id}>
            {libraryComponent.id.slice(0, 10) + '...'}
          </div>
        ),
        name: (
          <div key={'name-' + libraryComponent.id}>
            {libraryComponent?.title}
          </div>
        ),
        components: (
          <div key={'desc-' + libraryComponent.id}>
            {libraryComponent?.components.length}
          </div>
        ),
        owner: (
          <div key={'status-' + libraryComponent.id}>
            {libraryComponent?.owner}
          </div>
        ),
        pdfPreviewUrl: (
          <div key={'perms-' + libraryComponent.id}>
            {libraryComponent?.pdfPreviewUrl}
          </div>
        ),
        playbackID: (
          <div key={'perms-' + libraryComponent.id}>
            {libraryComponent?.playbackID}
          </div>
        ),
        type: (
          <div key={'perms-' + libraryComponent.id}>
            {libraryComponent?.type}
          </div>
        ),
        urlFile: (
          <div key={'perms-' + libraryComponent.id}>
            {libraryComponent?.urlFile
              ? libraryComponent?.urlFile?.slice(0, 10) +
              '...' +
              libraryComponent?.urlFile?.slice(-10, -1)
              : ''}
          </div>
        ),
        created: (
          <div key={'date-' + libraryComponent.id}>
            {moment(libraryComponent?.created).format('MM/DD/YYYY')}
          </div>
        ),
        updated: (
          <div key={'date2-' + libraryComponent.id}>
            {libraryComponent?.updated
              ? moment(libraryComponent?.updated).format('MM/DD/YYYY')
              : '-'}
          </div>
        ),

        action:
          <ActionsCell item={libraryComponent}
                       editCallback={() => history.push('../library-components/' + libraryComponent.id)}
                       deleteCallback={setLibraryComponentsIdForDelete}
                       deleteModalCallback={setModalDeleteStatus}
          />


      });
    });
  }
  return { libraryComponentsToPush, amount };
};

