// symbol for switch Off
export const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingLeft: 30,
      }}
    >
      {" "}
    </div>
  )
}
// symbol for switch On
export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 0,
        paddingLeft: "14px",
      }}
    ></div>
  )
}