import React, { useState } from 'react';

import { generateRandomUUID } from '../../services/table-helpers';
import Tooltip from './Tooltips';



const ActionsCell = ({ item, editCallback,reactivateCallback, deleteCallback, deleteModalCallback }) => {


  return (
    <div className='action-in-table' key={'action-' + item.id}>
      <Tooltip text="Edit" direction="down">
        <i
          key={'ico1-' + item.id}
          data-id={item.id}
          className='typcn typcn-edit perm-action'
          onClick={editCallback}
        />
      </Tooltip>


      {

        reactivateCallback&& <>
        {
          item.is_active ? (
            <Tooltip text='Deactivate' direction='down'>
              <i
                key={'ico2-' + item.id}
                data-id={item.id}
                onClick={reactivateCallback}
                className='typcn typcn-flash-outline perm-action'
              />
            </Tooltip>
          ) : (
            <Tooltip text='Activate' direction='down'>

              <i
                key={'ico3-' + item.id}
                className='typcn typcn-flash perm-action'
                data-id={item.id}
                onClick={reactivateCallback}
              />
            </Tooltip>
          )
        }
        </>
        }
      <Tooltip text="Delete" direction="down">

        <i
          key={'ico4-' + item.id}
          className='typcn typcn-delete-outline perm-action'
          data-id={item.id}
          onClick={e => {
            deleteCallback(e.target.dataset.id);
            deleteModalCallback(true);
          }}
        />
      </Tooltip>
    </div>
  );
};

export default ActionsCell;
