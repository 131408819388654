import { Alert, CardFooter, CardText, Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';


import { getPlaylistReq, updatePlaylistReq } from '../../../../services/axios/axios.playlists';

import { setNavValues } from '../../../../store/nav_breadcrumbs/actions';

import './playlist-edit.page.scss';
import { Styles } from '../../user/user_table';
import config from '../../../../config';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const PlaylistEditPage = () => {
  //state block
  const [currentID, setCurrentID] = useState('');
  const [alertErrStatus, setAlertErrStatus] = useState(false);
  const [alertSucStatus, setAlertSucStatus] = useState(false);
  const [dataToSend, setDataToSend] = useState({
    title: ''
  });
  const [anyChangesStatus, setAnyChangesStatus] = useState(false);
  const [dataToRender, setDataToRender] = useState({});
  const [errMessageAlert, setErrMessageAlert] = useState('');
//consts
  const history = useHistory();
  const params = useParams();

  const editFormInputs = e => {
    setAnyChangesStatus(true);
    const target = e.target;
    const value = target.value;
    dataToSend.title = value;
    setDataToSend(dataToSend);
  };

  ///async handlers


  const updatePlaylist = async () => {
    if (!anyChangesStatus) {
      return;
    }
    try {
      const response = await updatePlaylistReq(currentID, dataToSend);
      if (response?.data === 'OK') {
        setAlertSucStatus(true);
      } else {
        setAlertErrStatus(true);
        setTimeout(() => {
          setAlertErrStatus(false);
        }, 3000);
      }
    } catch (err) {
      console.log('update error: ' + err);
    }
  };


  const getPlaylist = async id => {
    const response = await getPlaylistReq(id);
    const playlist = response?.data;
    if (playlist) {
      setDataToRender(playlist);
      setDataToSend({
        title: playlist.title
      });
    }
  };

  ///useEffect block
  useEffect(() => {
    let id = params.id;
    setCurrentID(id);
    config.reduxStore.dispatch(setNavValues({
      mainTitle: config.breadCrumbsFirstTitle,
      title1: 'Playlists',
      title2: 'Edit'
    }));
    getPlaylist(id);
  }, []);

  //main block
  return (
    <Container className='p-0 user-main-wrapper ms-0'>
      <Styles className=' styles-inner-pages-wrapper'>
        <Container fluid className='edit-user-wrapper hide-scrollbar'>
          <Nav tabs className='nav-tabs-custom'>
            <NavItem>
              <NavLink className='active'>
                <span className='d-none d-sm-block'>Playlist</span>
              </NavLink>
            </NavItem>
          </Nav>

          <Row>
            <Col sm='12 mt-3'>
              <CardText className='mb-0'>
                <div className='modal-body'>
                  <AvForm>
                    <div className='mb-3'>
                      <AvField
                        name='fullname'
                        onChange={editFormInputs}
                        value={dataToRender.title}
                        label='Title  '
                        className={`quidzi-input-borderered`}
                        placeholder='Type title'
                        type='text'
                      />
                    </div>
                    <div className='modal-footer'>
                    </div>
                  </AvForm>
                </div>
              </CardText>
            </Col>
          </Row>
          {alertErrStatus && (
            <Alert
              color='danger'
              className='bg-danger text-white mb-0 error-custom-user-edit'
            >
              <strong>Oops!</strong> {errMessageAlert}
            </Alert>
          )}
          <CardFooter className='back-update-wrapper d-flex gap-3'>
            <button
              type='button'
              onClick={() => {
                history.goBack();
              }}
              className='quidzi-btn-dark'
              data-dismiss='modal'
            >
              Back
            </button>
            <button
              type='button'
              className='quidzi-btn-light'
              onClick={updatePlaylist}
            >
              Update Playlist
            </button>
          </CardFooter>

        </Container>
        {alertSucStatus && (
          <SweetAlert
            title='Success!'
            success
            showCancel
            confirmBtnText='Return to Playlists Page'
            cancelBtnText='Continue'
            confirmBtnBsStyle='success'
            cancelBtnBsStyle='warning'
            onConfirm={() => {
              setAlertSucStatus(false);
              history.push('../../playlists');
            }}
            onCancel={() => {
              setAlertSucStatus(false);
            }}
          >
            Playlist updated
          </SweetAlert>
        )}
      </Styles>
    </Container>


  );
};
export default PlaylistEditPage;
